import * as am5 from "@amcharts/amcharts5";

export class ColorTheme1 extends am5.Theme {
  setupDefaultRules() {
    
    this.rule("ColorSet").setAll({
      colors: [
        am5.color(0x186bff),
      ],
      reuse: true
    });
  }
}

export class ColorTheme2 extends am5.Theme {
  setupDefaultRules() {
    
    this.rule("ColorSet").setAll({
      colors: [
        am5.color(0xa90c7c),
        am5.color(0x36a5cc),
      ],
      reuse: true
    });
  }
}

export class ColorTheme3 extends am5.Theme {
  setupDefaultRules() {
    
    this.rule("ColorSet").setAll({
      colors: [
        am5.color(0x36a5cc),
        am5.color(0xa90c7c),
        am5.color(0xdbae03),
      ],
      reuse: true
    });
  }
}

export class ColorTheme4 extends am5.Theme {
  setupDefaultRules() {
    
    this.rule("ColorSet").setAll({
      colors: [
        am5.color(0x36a5cc),
        am5.color(0xa79e84),
        am5.color(0xa90c7c),
        am5.color(0xff6b00),
      ],
      reuse: true
    });
  }
}

export class ColorTheme5 extends am5.Theme {
  setupDefaultRules() {
    
    this.rule("ColorSet").setAll({
      colors: [
        am5.color(0xa90c7c),
        am5.color(0xdbae03),
        am5.color(0x89a943),
        am5.color(0x176bff),
        am5.color(0xff6b00),
      ],
      reuse: true
    });
  }
}

export class ColorTheme6 extends am5.Theme {
  setupDefaultRules() {
    
    this.rule("ColorSet").setAll({
      colors: [
        am5.color(0xa90c7c),
        am5.color(0xdbae03),
        am5.color(0x89a943),
        am5.color(0x176bff),
        am5.color(0xff6b00),
        am5.color(0x008c4b),
      ],
      reuse: true
    });
  }
}

export class ColorTheme10 extends am5.Theme {
  setupDefaultRules() {
    
    this.rule("ColorSet").setAll({
      colors: [
        am5.color(0x186bff),
        am5.color(0xa90c7c),
        am5.color(0xe51050),
        am5.color(0xff6b00),
        am5.color(0xdbae03),
        am5.color(0xa69e84),
        am5.color(0x88A943),
        am5.color(0x038c4a),
        am5.color(0x37a5cc),
      ],
      reuse: true
    });
  }
}
