import React, { useState } from "react";

import { Box, Link } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import styles from "./ExpandableList.module.scss";

const removeDuplicates = (personaPermissions, overridePermissions) => {
  const items = [
    ...personaPermissions.map((item) => ({ ...item, type: "persona" })),
    ...overridePermissions.map((item) => ({ ...item, type: "override" })),
  ];

  const uniqueItems = items.reduce((acc, item) => {
    const existingItem = acc.find((i) => i.title === item.title);
    if (!existingItem) {
      acc.push(item);
    }
    return acc;
  }, []);

  return uniqueItems;
};

const ExpandableList = ({ personaPermissions, overridePermissions }) => {
  const [expanded, setExpanded] = useState(false);

  if(!personaPermissions || !overridePermissions) {
    return null;
  }

  if(personaPermissions.length === 0 && overridePermissions.length === 0) {
    return null;
  }


  const items = removeDuplicates(personaPermissions, overridePermissions);
  const itemCount = expanded ? items.length : 3;

  const handleExpand = () => {
    setExpanded(true);
  };

  const handleCollapse = () => {
    setExpanded(false);
  };

  return (
    <Box py={2} maxWidth="200px">
      <ul className={styles.list}>
        {items.slice(0, itemCount).map((item) => (
          <Tooltip arrow title={`(${item.type}) ${item.title}`} key={item.id}>
            <li className={styles.list_item}>
              ({item.type === "persona" ? "P" : "O"}) {item.title}
            </li>
          </Tooltip>
        ))}
      </ul>
      {!expanded && items.length > 3 && (
        <Link sx={{ cursor: "pointer", fontSize: "12px", color:"blue", mb: 2 }} onClick={handleExpand}>Show More</Link>
      )}
      {expanded && (
        <Link sx={{ cursor: "pointer", fontSize: "12px", color:"blue", mb: 2 }} onClick={handleCollapse}>Show Less</Link>
      )}
    </Box>
  );
};

export default ExpandableList;
