import React, { useState } from "react";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import { Box, Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useGetAllBlogsQuery, useDeleteBlogMutation, useUpdateBlogMutation, usePostBlogMutation, useLazyGetBlogQuery } from "store/slices/blogSlice";
import Swal from "sweetalert2";

import BlogEditor from "./BlogEditor";
import BlogFilter from "./BlogFilter";

const BlogManagement = () => {
  const [searchKey, setSearchKey] = useState("");
  const [dateFilter, setDateFilter] = useState(null);

  const [selectedBlog, setSelectedBlog] = useState(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [deleteBlog] = useDeleteBlogMutation();
  const [updateBlog] = useUpdateBlogMutation();
  const [createBlog] = usePostBlogMutation();
  const [getBlog] = useLazyGetBlogQuery();

  const { data: blogs = [], isLoading } = useGetAllBlogsQuery();

  const searchedBlogs = blogs.filter(blog => blog.title.toLowerCase().includes(searchKey.toLowerCase()));
  const searchFilteredBlogs = dateFilter ? searchedBlogs.filter(blog => dayjs(blog.date, "YYYY-MM-DD").isAfter(dateFilter)) : searchedBlogs;

  const allCategories = blogs ? blogs.map(item => item.category) : [];
  const categories = [...new Set(allCategories.flat())];

  const handleEdit = async (row) => {
    try {
      const blog = await getBlog({ id: row.id }).unwrap();
      setSelectedBlog(blog);
      setOpen(true);
    } catch (error) {
      console.log({ error }); 
    }
  };

  const handleRemove = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Blog",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      await deleteBlog({ id: id }).unwrap();
      enqueueSnackbar("Blog has been deleted.", { variant: "success" });
    }
  };

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 120,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEdit(params.row)}>
            <EditOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => handleRemove(params.row.id)}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </>
      ),
    },
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "title",
      headerName: "Title",
      width: 200,
      editable: true,
    },
    {
      field: "description",
      headerName: "Description",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 300,
    },
    {
      field: "date",
      headerName: "Year",
      width: 100,
      editable: false,
    },
    {
      field: "category",
      headerName: "Category",
      // type: "number",
      width: 150,
      editable: true,
    },
  ];

  const handleCreateNew = () => {
    setSelectedBlog(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedBlog(null);
  };

  const handleSearch = (query) => {
    setSearchKey(query);
  };

  const handleDateFilterChange = (df) => {
    setDateFilter(df);
  };

  const handleCreate = async (values) => {
    await createBlog(values).unwrap();
  };

  const handleUpdate = async (values) => {
    await updateBlog(values).unwrap();
  };

  return (
    <>
      <Typography variant="h2" className=" flex items-center font-bold " mb={2}>
        <StickyNote2OutlinedIcon sx={{ mr: 1 }} /> Blog Management
      </Typography>
      <div>
        {/* Filter */}
        <BlogFilter
          onCreateNew={handleCreateNew}
          searchKey={searchKey}
          onSearch={handleSearch}
          dateFilter={dateFilter}
          onDateFilterChange={handleDateFilterChange}
        />
      </div>
      {/* Data Grid */}
      <Box sx={{ height: 800, width: "100%", backgroundColor: "white" }}>
        <DataGrid
          rows={searchFilteredBlogs}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}

          loading={isLoading}
          pageSizeOptions={[5]}
          // checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
      <BlogEditor
        categories={categories}
        open={open}
        blog={selectedBlog}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onClose={handleClose}
      />
    </>
  );
};

export default BlogManagement;
