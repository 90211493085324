
import React, { useEffect } from "react";

import { Box, Typography } from "@mui/material";
import Information from "components/LocationInsights/Information";
import MoreInformationPopover from "components/MoreInformationPopover/MoreInformationPopover";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useLazyGetExternalLocationInsightsQuery } from "store/slices/locationSlice";

import ExternalCostOfLabor from "./ExternalCostOfLabor";
import InternalCostOfLabor from "./InternalCostOfLabor";
import Question from "../../../components/Question";
import helpText from "../helpText";

const CostOfLabor = () => {
  const [currentParams] = useSearchParams();
  const [getLocationDetail] = useLazyGetExternalLocationInsightsQuery();
  const { externalData, isLoading } = useSelector((state) => state.location);

  useEffect(() => {
    const params = Object.fromEntries(currentParams.entries());
    getLocationDetail({ params });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParams]);

  if(isLoading) {
    return (
      <Box className="flex items-center justify-center w-full">
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }

  if(!externalData || externalData.length !== 1) {
    return (
      <Box mt={20}>
        <Typography variant="body1" textAlign="center" fontWeight="bold" fontSize="24px">
          No data available. Try selecting or changing the location and job title filters
        </Typography>
      </Box>
    );
  } 

  const data = externalData[0];
  const hireDifficultyData = data.rel_cost_labor && data.score_rel_cost_labor ? { value: data.rel_cost_labor, score: data.score_rel_cost_labor } : null;

  const autodeskHireDifficultyData = data.rel_cost_labor_adsk && data.score_rel_cost_labor_adsk ? { value: data.rel_cost_labor_adsk, score: data.score_rel_cost_labor_adsk } : null;
  return (
    <div>
      <Question text="How expensive is it to hire at this location?" />
      <Information>
        This page shows the compensation at this location compared to other locations with demand (Market) or compensation guidance (Autodesk) for this role.
        <br /><br />
        <MoreInformationPopover helpText={helpText.costOfLabor} />
      </Information>
      <Box mt={8}>
        <ExternalCostOfLabor data={hireDifficultyData} />
      </Box>
      <Box mt={4}>
        <InternalCostOfLabor data={autodeskHireDifficultyData} />
      </Box>
    </div>
  );
};

export default CostOfLabor;
