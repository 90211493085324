import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Typography } from "@mui/material";
import { ColorTheme3 } from "theme/chart";

const convertToChartData = (data) => {
  // Extract unique quarters
  const quarters = Object.keys(data.voluntary).sort();

  // Convert the object to an array of objects suitable for the chart
  const chartData = quarters.map(quarter => ({
    quarter: `FY${quarter.slice(2, 4)} ${quarter.slice(5)}`,
    voluntary: +data.voluntary[quarter],
    involuntary: +data.involuntary[quarter],
    total: +data.total[quarter]
  }));

  return chartData;
};

const AttritionTrends = ({ data }) => {
  return (
    <>
      <Typography variant="h4" fontWeight="bold" mb={4}>
        Attrition Trends
      </Typography>
      {data ? (
        <AttritionTrendsChart data={data} />
      ): (
        <Typography variant="body1">No data available</Typography>
      )}
    </>
  );
};

export default AttritionTrends;

const AttritionTrendsChart = ({ data }) => {
  const chartData = convertToChartData(data);
  
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");
    root._logo.dispose();

    root.setThemes([
      ColorTheme3.new(root)
    ]);

    let container = root.container.children.push(am5.Container.new(root, {
      layout: root.verticalLayout,
      width: am5.percent(100),
      height: am5.percent(100),
    }));

    let chart = container.children.push(am5xy.XYChart.new(root, {
      height: am5.percent(80), // Adjust the height to make space for the legend
    }));

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);

    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      maxDeviation: 0.3,
      categoryField: "quarter",
      renderer: am5xy.AxisRendererX.new(root, {
        minGridDistance: 30
      }),
      tooltip: am5.Tooltip.new(root, {})
    }));

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {}),
      min: 0,
      numberFormat: "#.00'%'",
    }));


    yAxis.get("renderer").labels.template.setAll({
      text: "{value.formatNumber('#.00')}%"
    });

    xAxis.data.setAll(chartData);

    function createSeries(name, field) {
      let series = chart.series.push(am5xy.LineSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: field,
        categoryXField: "quarter",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY.formatNumber('#.0')}%",
        })
      }));

      series.data.setAll(chartData);

      series.bullets.push(() => {
        return am5.Bullet.new(root, {
          locationY: 1,
          sprite: am5.Circle.new(root, {
            radius: 5,
            fill: series.get("fill")
          })
        });
      });

      series.strokes.template.setAll({
        strokeWidth: 3
      });

      series.appear();
    }

    createSeries("Voluntary", "voluntary");
    createSeries("Involuntary", "involuntary");
    createSeries("Total", "total");

    // Create legend and set its position below the chart
    let legend = container.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      layout: root.horizontalLayout
    }));
    legend.data.setAll(chart.series.values);

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <>
      <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
    </>
  );
};

