import { useState, useEffect } from "react";

import { Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

const AttritionDateRange = () => {
  const [dates, setDates] = useState(() => {
    const financialYear = dayjs().set("month", 1).set("date", 1); //1st Feb
    return [financialYear, dayjs().subtract(1, "month").endOf("month", "day")]; // last day of previous month
  });
  const [currentParams, setCurrentParams] = useSearchParams();

  useEffect(() => {
    if(dates.length < 2) {
      currentParams.delete("dates");
    } else {
      const [start, end] = dates;
      if (start.isBefore(end)) {
        currentParams.set("dates", dates.map(date => date.format("YYYY-MM-DD")));
        currentParams.set("startdate", start.format("YYYY-MM-DD"));
        currentParams.set("enddate", end.format("YYYY-MM-DD"));
      }
    }
    setCurrentParams(currentParams);
  }, [dates]);

  return <Box display="flex" gap={4} my={4}>
    <DatePicker
      label="Attrition from"
      value={dates[0]}
      onChange={(newValue) => {
        const [, end] = dates;
        setDates([dayjs(newValue), end]);
      }}
      minDate={dayjs().subtract(18, "months").startOf("month")}
      maxDate={dayjs().subtract(1, "month").endOf("month", "day")}
      shouldDisableDate={(day) => {
        return !day.isSame(day.startOf("month"), "day");
      }}
    />

    <DatePicker
      label="Attrition till"
      value={dates[1]}
      onChange={(newValue) => {
        const [start] = dates;
        setDates([start, dayjs(newValue)]); 
      }}
      minDate={dayjs().subtract(18, "months").startOf("month")}
      maxDate={dayjs().subtract(1, "month").endOf("month", "day")}
      shouldDisableDate={(day) => {
        return dayjs().isSame(day, "month") ? false : !day.isSame(day.endOf("month"), "day");
      }}
    />
  </Box>;
};

export default AttritionDateRange;
