import React, { useLayoutEffect } from "react";


import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Typography } from "@mui/material";
import { MyTheme } from "components/LocationInsights/theme";

function sortByLabels(a, b) {
  const getFirstNumber = (label) => {
    const match = label.match(/^\d+/); 
    return match ? parseInt(match[0], 10) : Infinity; 
  };

  return getFirstNumber(a.quarter) - getFirstNumber(b.quarter);
}


const convertToChartData = (data) => {
  // Extract unique quarters
  const quarters = Object.keys(data.voluntary).sort();

  // Convert the object to an array of objects suitable for the chart
  const chartData = quarters.map(quarter => ({
    quarter: quarter,
    voluntary: +data.voluntary[quarter],
    involuntary: +data.involuntary[quarter],
    total: +data.total[quarter]
  })).sort(sortByLabels);  

  return chartData;
};

const AttritionByTenure = ({ data }) => {
  return (
    <>
      <Typography variant="h4" fontWeight="bold" my={4}>
        Annualized Attrition by Tenure
      </Typography>
      {data ? (
        <AttritionByTenureChart data={data} />
      ): (
        <Typography variant="body1">No data available</Typography>
      )}
    </>
  );
};

export default AttritionByTenure;

const AttritionByTenureChart = ({ data }) => {
  const chartData = convertToChartData(data);

  useLayoutEffect(() => {
    let root = am5.Root.new("attrition-tenure");
    root._logo.dispose();

    root.setThemes([
      MyTheme.new(root)
    ]);


    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      layout: root.verticalLayout,
      paddingBottom: 100,
      paddingLeft: 100
    }));


    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
        dy: 50,
      })
    );


    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      minorGridEnabled: true,
      minGridDistance: 30 
    });

    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "quarter",
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {})
    }));

    xRenderer.grid.template.setAll({
      location: 1
    });

    xAxis.data.setAll(chartData);

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      numberFormat: "#.0'%'",
      min: 0,
      renderer: am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1
      })
    }));

    function makeSeries(name, fieldName) {
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        stacked: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: fieldName,
        categoryXField: "quarter"
      }));
    
      series.columns.template.setAll({
        tooltipText: "{name}, {categoryX}: {valueY}",
        tooltipY: am5.percent(10)
      });
      series.data.setAll(chartData);
    
      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();
    
      series.bullets.push(function (root, series, dataItem) {
        if (dataItem.get("valueY") > 0) {
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              text: "{valueY}%",
              fill: root.interfaceColors.get("alternativeText"),
              centerY: am5.p50,
              centerX: am5.p50,
              populateText: true
            }),
            dynamic: true
          });
        }
        return null;
      });

      if(fieldName === "involuntary") {
        series.bullets.push(function() {
          return am5.Bullet.new(root, {
            locationX: 0.5,
            locationY: 1,
            sprite: am5.Label.new(root, {
              text: "{total}%",
              centerY: am5.p50,
              x: am5.percent(50),
              y: am5.percent(50),
              populateText: true,
              dy: -10,
              dx: -20,
            })
          });
        });
      }

      legend.data.push(series);
    }

    makeSeries("Voluntary", "voluntary");
    makeSeries("Involuntary", "involuntary");

    chart.plotContainer.children.push(am5.Label.new(root, {
      text: "Tenure in Years",
      x: am5.percent(50),
      centerX: am5.percent(50),
      y: am5.percent(100),
      dy: 80,
      fontWeight: "bold"
    }));

    chart.plotContainer.children.push(am5.Label.new(root, {
      text: "Attrition (%)",
      x: am5.percent(0),
      centerX: am5.percent(50),
      centerY: am5.percent(100),
      y: am5.percent(50),
      rotation: -90,
      fontWeight: "bold",
      dx: -60
    }));

    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <>
      <div id="attrition-tenure" style={{ width: "100%", height: "500px" }}></div>
    </>
  );
};
