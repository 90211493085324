import React, { useRef } from "react";

import { InfoOutlined } from "@mui/icons-material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Psychology from "@mui/icons-material/Psychology";
import { Box, Grid, Paper, Table, TableBody, TableContainer, TableRow, TableCell, Button } from "@mui/material";
import PieChart from "components/LocationInsights/PieChart";
import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";
import * as XLSX from "xlsx";

import HeadcountChangeChart from "./components/charts/HeadcountChangeChart";
import InflowOutflowChart from "./components/charts/InflowOutflowChart";
import OccupancyChart from "./components/charts/OccupancyChart";
import InfoDialog from "./components/InfoDialog";
import RelativeCompensation from "./components/RelativeCompensation";

const dataTiles = [
  { key: "site_score", label: "Overall Site Score", maxVal: 10 },
  { key: "engagement_score", label: "Engagement Score", maxVal: 100 },
  { key: "annual_avg_attendance_rate", label: "Average Attendance Rate" },
  { key: "yoy_hc_change", label: "YoY Headcount Change" },
  { key: "corporate_income_tax", label: "Corporate Income Tax Rate" },
  { key: "fringe_benefits", label: "Fringe Benefits" },
  { key: "site_leader", label: "Site Leader" },
];

const LocationInsightsHubSummary = ({ data, setShow }) => {
  const { id } = useParams();
  const qiTableRef = useRef(null);
  const handleDownload = () => {
    if(qiTableRef.current) {
      const wb = XLSX.utils.table_to_book(qiTableRef.current);
      XLSX.writeFile(wb, "qualitative_insights.xlsx");
    }
  };
  return (
    <div>
      <div className="flex justify-between">
        <h2 className="text-xl font-extrabold my-4">Office Location Summary for {data.value.office_name}</h2>
        <CloseOutlinedIcon className="cursor-pointer" onClick={() => setShow(false)} />
      </div>
      {
        data?.value ? 
          <div>
            <div className="flex items-center flex-wrap gap-x-4 mb-4">
              {
                dataTiles.filter(tile => !!data.value[tile.key]).map(tile => {
                  return <Paper key={tile.key} className="flex flex-col items-center gap-1 p-4">
                    <div>
                      <span className="text-md font-bold">
                        {data.value[tile.key]}
                      </span>
                      <span className="text-xs">{tile.maxVal ? ` /${tile.maxVal}` : ""}</span>
                    </div>
                    <span className="text-sm font-medium">{tile.label}</span>
                  </Paper>;
                })
              }
            </div>

            {data.value?.qualitative_insights ? (
              <InfoDialog triggerComponent={
                <div className="flex items-center gap-2 mb-4 cursor-pointer">
                  <Psychology />
                  <span className="underline font-medium">Hub Outlook: Click here for Qualitative Insights</span>
                </div>
              }
              title="Qualitative Insights"
              description={
                <div className="flex flex-col gap-1">
                  <Button variant="outlined" startIcon={<FileDownloadOutlinedIcon />} onClick={handleDownload} className="!ml-auto">
                    Download
                  </Button>
                  <TableContainer component={Paper}>
                    <Table ref={qiTableRef}>
                      <TableBody>
                        {
                          Object.entries(data.value?.qualitative_insights ?? {}).map(([key, value]) => {
                            return <TableRow key={key}>
                              <TableCell>
                                {key.split("_").map(word => word[0].toUpperCase() + word.slice(1)).join(" ")}
                              </TableCell>
                              <TableCell><span dangerouslySetInnerHTML={{ __html: value }}/></TableCell>
                            </TableRow>;
                          })
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              }
              />
            ):null}

            <div className="text-lg mb-4 font-medium">
                How has the <i>{data.value.office_name}</i> office changed in past year?
            </div>
            

            <div className="mb-6 font-medium">
                Tip: Want to see more Headcount and Attrition metrics for this office location? Visit this tools <Link className="text-blue-500 underline" to={`/dashboard/${id}/location-insights/internal-view`}>Internal View</Link>
            </div>

            <Grid container spacing={2} className="mb-4">
              {data.value?.yoy_div_headcount_change ? (
                <Grid item xs={12} lg={6}>
                  <p className="font-medium">{data.value?.yoy_div_headcount_change?.chart_header}</p>
                  <HeadcountChangeChart data={data.value?.yoy_div_headcount_change?.chart_data} />
                </Grid>
              ) : null}
              {data.value?.monthly_attendance_rate ? (
                <Grid item xs={12} lg={6}>
                  <p className="font-medium">{data.value?.monthly_attendance_rate?.chart_header}</p>
                  <OccupancyChart data={data.value?.monthly_attendance_rate?.chart_data.map(data => {
                    return {
                      attendance: Number(data.attendance_rate.replace("%", "")),
                      month: new Date(data.month).getTime(),
                    };
                  })} />
                </Grid>
              ) : null}
              
              {data.value?.inflow_outflow ? (
                <Grid item xs={12} lg={6}>
                  <p className="font-medium">{data.value?.inflow_outflow?.chart_header}</p>
                  <p className="flex items-start mt-2 text-sm">
                    <InfoOutlined />
                    <span className="ml-2">{data.value?.inflow_outflow?.description}</span>
                  </p>
                  
                  <InflowOutflowChart data={data.value?.inflow_outflow?.chart_data.map(data => {
                    return {
                      entries: data.inflow,
                      exits: data.outflow,
                      month: dayjs(data.month).format("MMM, YY"),
                    };
                  })} />
                </Grid>
              ) : null}

              {data.value?.relative_compensation || data.value?.relative_compensation_general ? (
                <Grid item xs={12} lg={6}>
                  <RelativeCompensation data={data} />
                </Grid>
              ) : null}
            </Grid>

            <div className="text-lg mb-4 font-medium">
                Who is assigned to the {data.value.office_name} office today?
            </div>

            <Grid container spacing={4}>
              {data.value?.radford_hc && (
                <Grid item xs={12} lg={6}>
                  <p className="font-medium">{data.value?.radford_hc?.chart_header}</p>
                  <PieChart id="radford-hc" data={Object.entries(data.value?.radford_hc?.chart_data).map(([role, headcount]) => ({ role, headcount }))} labelKey="role" valueKey="headcount" />
                </Grid>
              )}
              {data.value?.tenure_hc && (
                <Grid item xs={12} lg={6}>
                  <p className="font-medium">{data.value?.tenure_hc?.chart_header}</p>
                  <PieChart id="tenure-hc" data={Object.entries(data.value?.tenure_hc?.chart_data).sort().map(([tenure, headcount]) => ({ tenure, headcount }))} labelKey="tenure" valueKey="headcount" />
                </Grid>
              )}
            </Grid>
          </div> :
          <Box className="flex items-center justify-center w-full h-full">
            No data available
          </Box>
      }
    </div>
  );
};

export default LocationInsightsHubSummary;
