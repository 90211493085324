import { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

import { MyTheme } from "./theme";


const LineBarChart = ({ data, dataKeys=[], id, height="400px", categoryKey="category", unit, lineValueKey, lineValueLabel, showGrid=true, xLabel, yLabel }) => {

  // This code will only run one time
  useLayoutEffect(() => {
    const root = am5.Root.new(id);

    root.setThemes([MyTheme.new(root)]);
    root._logo.dispose();

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout
      })
    );

    const yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1
    });

    // Create Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: yRenderer,
        numberFormat: unit ? `# '${unit}'`: "#",
        extraMax: 0.05,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    if(yLabel) {
      yAxis.children.unshift(am5.Label.new(root, {
        rotation: -90,
        text: yLabel,
        fontSize: 14,
        y: am5.percent(50),
        centerX: am5.percent(50),
        centerY: am5.percent(50),
        dx: -50 // Adjust this to move the label closer or further from the axis
      }));
    }

    const xRenderer = am5xy.AxisRendererX.new(root, {
      minorGridEnabled: true,
      minGridDistance: 140,    
      minorLabelsEnabled: true,
      strokeOpacity: 0.1,
      cellStartLocation: 0.1,
      cellEndLocation: 0.9
    });

    // Create X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: {
          timeUnit: "month",
          count: 4
        },
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
        // extraMax: 0.01,
        // extraMin: 0.01,
        paddingInner: 10,
        paddingOuter: 10 
      })
    );

    if(xLabel) {
      // Add X-axis label
      xAxis.children.push(am5.Label.new(root, {
        text: xLabel,
        fontSize: 14,
        x: am5.percent(50),
        centerX: am5.percent(50),
        centerY: am5.percent(100),
      }));
    }

    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
        width: am5.percent(80),
        layout: am5.GridLayout.new(root, {
          maxColumns:2,
          fixedWidthGrid: true
        })
      })
    );

    if(!showGrid) {
      yRenderer.grid.template.set("forceHidden", true);
      xRenderer.grid.template.set("forceHidden", true);
    }

    xAxis.data.setAll(data);

    const paretoAxisRenderer = am5xy.AxisRendererY.new(root, { opposite: true });
    const paretoAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: paretoAxisRenderer,
      min: 0,
      max: 100,
      strictMinMax: true
    }));

    paretoAxisRenderer.grid.template.set("forceHidden", true);
    paretoAxis.set("numberFormat", "#'%");

    function makeSeries(name, yValueField, xCategoryField) {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name || "Series",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: yValueField,
          valueXField: xCategoryField,
        })
      );

      series.columns.template.setAll({
        tooltipText: "{name}: {valueY}",
        width: am5.percent(50),
        tooltipY: 0,
        minWidth: 8
      });
      
      series.bullets.push(function() {
        return am5.Bullet.new(root, {
          locationX: 0.5,
          locationY: 1,
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            centerX: am5.percent(50),
            centerY: am5.percent(80),
            fontSize: 12,
            populateText: true
          })
        });
      });

      series.data.setAll(data);
      series.appear();

      legend.data.push(series);
    }

    dataKeys.forEach(({ name, yKey, countKey }) => {
      makeSeries(name, yKey, categoryKey, countKey);
    });

    const lineSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: lineValueLabel,
        xAxis: xAxis,
        yAxis: paretoAxis,
        valueYField: lineValueKey,
        valueXField: categoryKey,
        tooltip: am5.Tooltip.new(root, {
          labelText: "{name}: {valueY}%"
        }),
      })
    );


    // Set tick label size for X axis
    xAxis.get("renderer").labels.template.setAll({
      fontSize: 12 // Set your desired font size here
    });

    // Set tick label size for Y axis
    yAxis.get("renderer").labels.template.setAll({
      fontSize: 12 // Set your desired font size here
    });

    // Set tick label size for Y axis
    paretoAxis.get("renderer").labels.template.setAll({
      fontSize: 12 // Set your desired font size here
    });

    // Actual bullet
    lineSeries.bullets.push(function () {
      var bulletCircle = am5.Circle.new(root, {
        radius: 5,
        fill: lineSeries.get("fill")
      });
      return am5.Bullet.new(root, {
        sprite: bulletCircle
      });
    });

    lineSeries.data.setAll(data);
    lineSeries.strokes.template.setAll({
      strokeWidth: 3 // Change this value to set the line width
    });
    legend.data.push(lineSeries);

    // Add cursor
    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id={id} style={{ width: "100%", height }}></div>;
};
export default LineBarChart;
