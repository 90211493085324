import React, { useState } from "react";

import { CloseOutlined, InfoOutlined, Circle, ArrowCircleRightOutlined } from "@mui/icons-material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, Typography, TextField, InputAdornment, Button, Chip, Tooltip, IconButton } from "@mui/material";
import clsx from "clsx";
import { DownloadResources } from "components/LocationInsights/DownloadResources";
import { Link, useParams } from "react-router-dom";
import { useLazySearchPortalQuery } from "store/slices/locationSlice";

import useGroupedMenus from "../useGroupedMenus";

const getLinks = (id) => {
  return {
    internal: [
      {
        title: "Headcount",
        to: `/dashboard/${id}/location-insights/internal-view/detail/headcount`
      },
      {
        title: "Attrition",
        to: `/dashboard/${id}/location-insights/internal-view/detail/attrition`
      },
      {
        title: "Timezone",
        to: `/dashboard/${id}/location-insights/internal-view/detail/timezone`
      },
      {
        title: "Leadership Depth",
        to: `/dashboard/${id}/location-insights/internal-view/detail/leadership-depth`
      },
      {
        title: "Open and Budgeted roles",
        to: `/dashboard/${id}/location-insights/internal-view/detail/recruitment`
      },
      {
        title: "Teams Based at Location",
        to: `/dashboard/${id}/location-insights/internal-view/detail/team-distribution`
      },
    ],
    external: [
      {
        title: "Talent Supply and Demand",
        to: `/dashboard/${id}/location-insights/external-view/detail/supply-and-demand`
      },
      {
        title: "Hiring Difficulty",
        to: `/dashboard/${id}/location-insights/external-view/detail/hiring-difficulty`
      },
      {
        title: "Talent Competition",
        to: `/dashboard/${id}/location-insights/external-view/detail/talent-competition`
      },
      {
        title: "Role Analysis",
        to: `/dashboard/${id}/location-insights/external-view/detail/role-analysis`
      },
      {
        title: "Relative Compensation Cost",
        to: `/dashboard/${id}/location-insights/external-view/detail/cost-of-labor`
      }
    ]
  };
};

const SearchBox = ({ className, disabled, onSearch }) => {
  const [searchKey, setSearchKey] = useState("");
  const handleTextChange = (e) => {
    setSearchKey(e.target.value);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter" && onSearch && searchKey) {
      onSearch(searchKey);
    }
  };
  return <TextField
    placeholder="Whats on your mind?"
    size="small"
    id="outlined-start-adornment"
    className={className}
    disabled={disabled}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position="end">
          <Chip label="Beta" />
        </InputAdornment>
      ),
    }}
    onChange={handleTextChange}
    onKeyDown={handleEnter}
    value={searchKey}
  />;
};



const LocationInsightsOverviewPage = () => {
  const { id } = useParams();
  const groupedMenus = useGroupedMenus(id);
  const [searchQuery, searchResult] = useLazySearchPortalQuery();
  const [showSearchResult, setShowSearchResult] = useState(false);

  return (
    <Box pt={4}>

      <Box display="flex" flexDirection="column" alignItems="center">
        <Box display="flex" className="w-full justify-end items-center px-8 gap-4">
          <SearchBox disabled={searchResult?.isFetching} onSearch={(query) => {
            searchQuery({ params: { query, resource_id: "location-insights" } }, true).then(() =>
              setShowSearchResult(true)
            );
          }} />
          <DownloadResources />
        </Box>
        {
          showSearchResult ?
            <Box display="flex" alignItems="center" width="100%" px={4}>
              <Typography variant="h1" className="font-extrabold" fontWeight={800} marginTop={8} marginBottom={8} marginLeft="auto">
                Search Results
              </Typography>
              <IconButton color="primary" onClick={() => setShowSearchResult(false)} sx={{ ml: "auto" }}>
                <CloseOutlined />
              </IconButton>
            </Box>
            : null
        }
        {
          !showSearchResult ?
            <>
              <Typography variant="h1" className="font-extrabold" fontWeight={800} marginTop={8}>
                Key drivers for location strategy
              </Typography>
              <Typography variant="p" className="font-extrabold" fontWeight={400} marginTop={2}>
                Entering a new market or expanding current locations requires thorough analysis of these drivers
              </Typography>
            </> : null
        }
      </Box>


      <Box sx={{ px: 8, pb: 10 }}>
        {
          showSearchResult && searchResult?.data ?
            <>
              <Chip label={`${searchResult.data?.total_results} matching results`} />
              <Grid container sx={{ mt: 1 }}>
                {
                  searchResult.data.results.map(result => {
                    return <Grid item key={result.id} xs={12} md={4} >
                      <Box className="flex items-center gap-1 p-4">
                        {
                          result?.content?.startsWith("http") ?
                            <a href={result.content} target="_blank" rel="noreferrer" className="text-blue-500 text-xl font-extrabold">{result.title}</a> :
                            <>
                              <Tooltip title={result.content} placement="top">
                                <InfoOutlined />
                              </Tooltip>
                              <span className="text-xl font-extrabold">{result.title}</span>
                            </>
                        }
                      </Box>
                    </Grid>;
                  })
                }
              </Grid>
            </> : null
        }
        <Grid container className={clsx("mb-4", { "hidden": showSearchResult })}>
          <Grid item xs={12} md={4} className="p-12">
            <Box className="flex flex-col">
              <Typography className="font-extrabold text-black-500" fontWeight={800} fontSize={24}>
                Core
              </Typography>
              <Box display="flex" gap={1} mt={1}>
                <Chip label="Functional" />
              </Box>
              <ul className="mx-0 mt-4">
                <li className="mb-1">Ease of doing business</li>
                <li className="mb-1">Safety and security</li>
                <li className="mb-1">Geopolitical conditions</li>
              </ul>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className="p-12">
            <Box className="flex flex-col">
              <Typography className="font-extrabold text-black-500" fontWeight={800} fontSize={24}>
                Strategic
              </Typography>
              <Box display="flex" gap={1} mt={1}>
                <Chip label="Growth" />
                <Chip label="Resilience" />
              </Box>
              <ul className="mx-0 mt-4">
                <li className="mb-1">Talent pool landscapes</li>
                <li className="mb-1">Functional co-location</li>
                <li className="mb-1">Total rewards</li>
              </ul>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className="p-12">
            <Box className="flex flex-col">
              <Typography className="font-extrabold text-black-500" fontWeight={800} fontSize={24}>
                Operational
              </Typography>
              <Box display="flex" gap={1} mt={1}>
                <Chip label="Scalability" />
              </Box>
              <ul className="mx-0 mt-4">
                <li className="mb-1">Real estate</li>
                <li className="mb-1">Site success</li>
                <li className="mb-1">Leadership and Infra support</li>
              </ul>
            </Box>
          </Grid>
        </Grid>

        <Box className={clsx("bg-[#f8f8f8] rounded-xl px-4 py-2 mb-6", { "hidden": showSearchResult })}>
          <Typography variant="h2" className="font-extrabold" fontWeight={800} marginTop={8} textAlign="center">
            Scenarios to utilize location insights
          </Typography>
          <Typography variant="body1" className="font-extrabold" fontWeight={400} marginTop={2} textAlign="center">
            Explore these dashboards for scenario-based guided analysis
          </Typography>
          <Grid container >
            {
              groupedMenus.map(group => {
                return <Grid item key={group.id} xs={12} md={4} className="rounded-xl p-4">
                  <Box className="rounded-xl bg-white p-8 h-full">
                    <Box className="flex flex-col">
                      <span className="text-xl font-extrabold">{group.name}</span>
                      <p className="mt-3">
                        {group.description}
                      </p>
                      <Box className="flex flex-col gap-6 mt-6">
                        {
                          group.menus.map(menu => {
                            return <Link to={menu.to} key={menu.title} className="text-blue-500">
                              {menu.title}
                            </Link>;
                          })
                        }
                      </Box>
                    </Box>
                  </Box>
                </Grid>;
              })
            }
          </Grid>
          {/*
          <Typography variant="body1" marginY={2} fontWeight={400} fontSize={14}>
          Unable to locate a scenario that fits your requirement?
            <Link to="#" className="text-black underline ml-px"> Request a scenario here </Link>
          </Typography>
          */}
        </Box>

        <Box className={clsx("bg-black rounded-xl p-8 mb-2", { "hidden": showSearchResult })}>
          <Box display="flex" alignItems="flex-start" justifyContent="space-between" borderBottom="1px solid white" pb={2} mb={2}>
            <Box px={2}>
              <Typography color="#f9f9f9" variant="body1" className="font-extrabold" fontWeight={800} fontSize={36}>
                Explore these dashboards for specific research
              </Typography>
              <Typography color="#e8e8e8" variant="body1" className="font-extrabold" fontWeight={400} marginTop={1}>
                Utilize analysis workbook to efficiently consolidate and present research findings
              </Typography>
            </Box>
            <DownloadResources variant="dark" />
          </Box>
          <Grid container >
            <Grid item xs={12} md={4}>
              <Box mr={4} className="rounded-b-xl p-8 border-t-4 border-blue-600 bg-white">
                <Box className="flex flex-col h-[500px]">
                  <span className="text-xl font-extrabold">Location Summary</span>
                  <p className="mt-3">
                    Aggregated for large, medium and small offices
                  </p>
                  <p className="mt-6">
                    Metrics Included:
                  </p>
                  <ul className="ml-2 mt-6">
                    <li className="mb-1">Functional representation</li>
                    <li className="mb-1">YoY growth</li>
                    <li className="mb-1">Qualitative insights</li>
                    <li className="mb-1">Office definitions</li>
                    <li className="mb-1">Guidance by size</li>
                    <li className="mb-1">Other relevant metrics</li>
                  </ul>
                  <Button variant="contained" color="primary" endIcon={<ArrowCircleRightIcon />} sx={{ marginTop: "auto" }}>
                    <Link to={`/dashboard/${id}/location-insights/hub-summary`}>
                      Open location summary
                    </Link>
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box mr={4} className="rounded-b-xl p-8 border-t-4 border-blue-600 bg-white">
                <Box className="flex flex-col h-[500px]">
                  <span className="text-xl font-extrabold">Internal</span>
                  <p className="mt-3">
                    Understand and compare divisions’ footprint with guided insights
                  </p>
                  <p className="mt-6">
                    Metrics Included:
                  </p>
                  <Box className="flex flex-col gap-6 mt-6">
                    {
                      getLinks(id).internal.map(link => {
                        return <Link to={link.to} key={link.title} className="text-blue-500">
                          {link.title}
                        </Link>;
                      })
                    }
                  </Box>
                  <Button variant="contained" color="primary" endIcon={<ArrowCircleRightIcon />} sx={{ marginTop: "auto" }}>
                    <Link to={`/dashboard/${id}/location-insights/internal-view`}>
                      Open Internal
                    </Link>
                  </Button>
                </Box>
              </Box>
            </ Grid>
            <Grid item xs={12} md={4} className="rounded-b-xl p-8 border-t-4 border-blue-600 bg-white">
              <Box className="flex flex-col h-[500px]">
                <span className="text-xl font-extrabold">Market</span>
                <p className="mt-3">
                  Analyze talent availability, benchmark market demand
                </p>
                <p className="mt-6">
                  Metrics Included:
                </p>
                <Box className="flex flex-col gap-6 mt-6">
                  {
                    getLinks(id).external.map(link => {
                      return <Link to={link.to} key={link.title} className="text-blue-500">
                        {link.title}
                      </Link>;
                    })
                  }
                </Box>
                <Button variant="contained" color="primary" endIcon={<ArrowCircleRightIcon />} sx={{ marginTop: "auto" }}>
                  <Link to={`/dashboard/${id}/location-insights/external-view`}>
                    Open External
                  </Link>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="flex items-center justify-center flex-col gap-2 mt-6">
          <Typography variant="body1" fontWeight={700} fontSize={21}>
          If you need more information or have any questions, please visit the Help section.
          </Typography>
          <Button variant="outlined" color="primary" endIcon={<ArrowCircleRightOutlined />}>
            <Link to={`/dashboard/${id}/location-insights/faqs`}>
              Open Help
            </Link>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LocationInsightsOverviewPage;
