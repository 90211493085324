import React, { useEffect, useState } from "react";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import CardListFilter from "components/CardListFilter/CardListFilter";
import dayjs from "dayjs";
import InfoDialog from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsHubPage/components/InfoDialog";
import { Link, useSearchParams } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { useGetAllEmployeeListeningsQuery } from "store/slices/employeeListeningSlice";

import EmployeeInsightsPulse from "./EmployeeInsightsPulse";
import LifecycleSurveys from "./LifecycleSurveys";

const typeOptions = [
  {
    label: "Blog",
    value: "blog"
  },
  {
    label: "Research",
    value: "research"
  },
  {
    label: "Dashboard",
    value: "dashboard"
  },
];

const EmployeeListeningPage = () => {
  const [tab, setTab] = React.useState("1");
  const [searchKey, setSearchKey] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [currentParams, setCurrentParams] = useSearchParams();
  const { data = [], isLoading } = useGetAllEmployeeListeningsQuery();

  const searchedItems = data.filter(item => item.title.toLowerCase().includes(searchKey.toLowerCase()));
  const searchFilteredItems = dateFilter ? searchedItems.filter(item => dayjs(item.date, "YYYY-MM-DD").isAfter(dateFilter)) : searchedItems;
  const filterResult = typeFilter ? searchFilteredItems.filter(item => item.item_type === typeFilter) : searchFilteredItems;

  const handleSearch = (query) => {
    setSearchKey(query);
  };

  const handleDateFilterChange = (df) => {
    setDateFilter(df);
  };

  const handleChangeCategory = (type) => {
    setTypeFilter(type);
  };

  const handleClearFilter = () => {
    setDateFilter("");
    setTypeFilter("");
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
    currentParams.set("tab", newValue);
    setCurrentParams(currentParams);
  };

  useEffect(() => {
    if(currentParams.get("tab")) {
      setTab(currentParams.get("tab"));
    }
  }, [currentParams]);

  if(isLoading) {
    return (
      <Box className="text-center" py={8}>
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }

  const surveyItems = filterResult.filter( i => i.type === "survey");

  return (
    <Box maxWidth="1300px">

      <TabContext value={tab}>
        <Box display="flex" justifyContent="center">
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label={<Typography variant="h5" fontWeight="bold" sx={{ textTransform: "none" }}>Employee Insights (Glint)</Typography>} value="1" />
            {surveyItems.length > 0 && <Tab label={<Typography variant="h5" fontWeight="bold" sx={{ textTransform: "none" }}>Employee Experience Listening</Typography>} value="2" />}
            <Tab label={<Typography variant="h5" fontWeight="bold" sx={{ textTransform: "none" }}>More Resources</Typography>} value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Typography variant="body1" fontSize="14px" textAlign="center">
          Click on the&nbsp;
            <Link sx={{ cursor: "pointer" }} onClick={() => handleChange(null, 1)}>
              <b>Employee Experience Listening</b>
            </Link>&nbsp;tab to see&nbsp;Employee Experience Listening.
          </Typography>
          <Box pt={8}>
            <CardListFilter
              searchKey={searchKey}
              onSearch={handleSearch}
              dateFilter={dateFilter}
              onDateFilterChange={handleDateFilterChange}
              filterValue={typeFilter}
              filterLabel="Type"
              filterList={typeOptions}
              onFilterChange={handleChangeCategory}
              onClearFilter={handleClearFilter}
            />
          </Box>
          <EmployeeInsightsPulse items={filterResult.filter( i => i.type === "pulse")} />
        </TabPanel>
        {surveyItems.length > 0 && (
          <TabPanel value="2">
            <Typography variant="body1" fontSize="14px" textAlign="center">
            Every employee's experience matters at Autodesk. Discover what candidates, interns and employees are sharing about their own experiences at Autodesk.
            </Typography>
            <InfoDialog description={<div className="flex flex-col gap-2">
              <div>
                The EX Listening dashboards are comprehensive tools that track the aggregate results of Employee and Candidate Listening surveys conducted by the People Insights and Solutions Design Team. Currently, the dashboard is only available to the People Insights team, PPL XLT, People Business Partners, People Consultants, and the Talent Acquisition Leadership Team.
                <br/><br/>
              </div>
              <div>
                <b>Important Note on Sharing Dashboard Information For Those Without Access:</b> This dashboard has been designed to share insights (including screenshots) relevant to each specific division with leaders of that organization or division. It is not intended to share insights unrelated to a particular leader’s division or organization. If you have any questions, please contact <a href="mailto:shannon.fox@autodesk.com">Shannon Fox</a>.
                <br/><br/>
              </div>
              <div>
                This dashboard is to be used as an indicator of where to look for possible areas of opportunity in the organization consistent with Autodesk policies. This dashboard is not to be used for making any individual employment decision, as a measure for fairness or discrimination, or to attempt to draw legal conclusions. It is also not to be used to create additional insights or analysis – if you have questions, or wish to share content from the dashboards, including screenshots, with individuals who do not have access, or in a way that is not envisioned as noted above, or wish to perform deeper analysis on the data, please contact Shannon Fox, Manager of EX Listening and Research.
                <br/><br/>
              </div>
              <div>
                <b>Legal Disclaimer:</b> The dashboard is for internal use only, and only for the recipient. It is confidential and proprietary to Autodesk, Inc. Any disclosure, reproduction, dissemination or further distribution of any of its content either internal or external to the organization is strictly prohibited.
              </div>
            </div>}
            triggerComponent={<Typography variant="body1" fontSize="14px" textAlign="center" sx={{ ":hover": {
              cursor: "pointer",
              textDecoration: "underline"
            } }}>
              <br/> Currently, this dashboard is only available to the People Insights & Solutions Design team, PPL XLT, People Business Partners, People Consultants, and the Talent Acquisition Leadership Team. Please do not share information (including screenshots) from these EX Listening dashboards with those who do not have access, except to share division or organization-specific information with the respective leaders of those organizations or divisions. If you have any questions on data sharing and use for this dashboard, please <b>click here for more information </b>
            </Typography>}
            />
            <LifecycleSurveys items={filterResult.filter( i => i.type === "survey")} />
          </TabPanel>
        )}
        <TabPanel value="3">
          <Typography variant="body1" fontSize="14px" textAlign="center">
          Click on the&nbsp;
            <Link sx={{ cursor: "pointer" }} onClick={() => handleChange(null, 0)}>
              <b>Employee Insights (Glint)</b>
            </Link>&nbsp;tab to see&nbsp;Employee Insights (Glint) resources.&nbsp;or&nbsp;
            <Link sx={{ cursor: "pointer" }} onClick={() => handleChange(null, 1)}>
              <b>Employee Experience Listening</b>
            </Link>&nbsp;tab to see&nbsp;Employee Experience Listening.
          </Typography>
          <Box pt={8}>
            <CardListFilter
              searchKey={searchKey}
              onSearch={handleSearch}
              dateFilter={dateFilter}
              onDateFilterChange={handleDateFilterChange}
              filterValue={typeFilter}
              filterLabel="Type"
              filterList={typeOptions}
              onFilterChange={handleChangeCategory}
              onClearFilter={handleClearFilter}
            />
          </Box>
          <EmployeeInsightsPulse items={filterResult.filter( i => i.type === "other")} />
        </TabPanel>
      </TabContext>

      {/* <Box display="flex" justifyContent="center">
        <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label={<Typography variant="h5" fontWeight="bold" sx={{ textTransform: "none" }}>Employee Insights (Glint)</Typography>} {...a11yProps(0)} />
          <Tab label={<Typography variant="h5" fontWeight="bold" sx={{ textTransform: "none" }}>Employee Experience Listening</Typography>} {...a11yProps(1)} />
          <Tab label={<Typography variant="h5" fontWeight="bold" sx={{ textTransform: "none" }}>More Resources</Typography>} {...a11yProps(2)} />
        </Tabs>
      </Box> */}
      {/* {tab === 0 && (
        <Typography variant="body1" fontSize="14px" textAlign="center" mt={2}>
          Click on the&nbsp;
          <Link sx={{ cursor: "pointer" }} onClick={() => handleChange(null, 1)}>
            <b>Employee Experience Listening</b>
          </Link>&nbsp;tab to see&nbsp;Employee Experience Listening.
        </Typography>
      )}

      {tab === 1 && (
        <Typography variant="body1" fontSize="14px" textAlign="center" mt={2}>
          Every employee's experience matters at Autodesk. Discover what candidates, interns and employees are saying about us.
        </Typography>
      )}

      {tab === 2 && (
        <Typography variant="body1" fontSize="14px" textAlign="center" mt={2}>
          Click on the&nbsp;
          <Link sx={{ cursor: "pointer" }} onClick={() => handleChange(null, 0)}>
            <b>Employee Insights (Glint)</b>
          </Link>&nbsp;tab to see&nbsp;Employee Insights (Glint) resources.&nbsp;or&nbsp;
          <Link sx={{ cursor: "pointer" }} onClick={() => handleChange(null, 1)}>
            <b>Employee Experience Listening</b>
          </Link>&nbsp;tab to see&nbsp;Employee Experience Listening.
        </Typography>
      )}

      {tab !== 1 && (
        <Box pt={4}>
          <CardListFilter
            searchKey={searchKey}
            onSearch={handleSearch}
            dateFilter={dateFilter}
            onDateFilterChange={handleDateFilterChange}
            filterValue={typeFilter}
            filterLabel="Type"
            filterList={typeOptions}
            onFilterChange={handleChangeCategory}
            onClearFilter={handleClearFilter}
          />
        </Box>
      )}

      <Box>
        <CustomTabPanel sx={{ mt:8 }} value={tab} index={0}>
          <EmployeeInsightsPulse items={filterResult.filter( i => i.type === "pulse")} />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
          <LifecycleSurveys items={filterResult.filter( i => i.type === "survey")} />
        </CustomTabPanel>
        <CustomTabPanel sx={{ mt: 8 }} value={tab} index={2}>
          <EmployeeInsightsPulse items={filterResult.filter( i => i.type === "other")} />
        </CustomTabPanel>
      </Box> */}
    </Box>
  );
};

export default EmployeeListeningPage;
