import React, { useMemo } from "react";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { MaterialReactTable, MRT_ToggleFullScreenButton, useMaterialReactTable } from "material-react-table";

import EmployeeListeningCreate from "./EmployeeListeningCreate";

const EmployeeInsightsManagement = ({ data, isLoading = false, onEdit, onRemove, onCreateNew }) => {

  const columns = useMemo(() => {
    return [
      {
        accessorKey: "actions",
        header: "Actions",
        type: "actions",
        size: 120,
        enableSorting: false,
        enableGlobalFilter: false, 
        Cell: ({ row }) => (
          <>
            <IconButton onClick={() => onEdit(row.original)}>
              <EditOutlinedIcon />
            </IconButton>
            <IconButton onClick={() => onRemove(row.original.id)}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </>
        ),
      },
      { 
        accessorKey: "id", 
        header: "ID", 
        size: 90,
        enableSorting: true,
        enableGlobalFilter: false, 
      },
      {
        accessorKey: "title",
        header: "Title",
        size: 200,
        enableSorting: true,
        enableGlobalFilter: true, 
      },
      {
        accessorKey: "description",
        header: "Description",
        description: "This column has a value getter and is not sortable.",
        enableSorting: false,
        enableGlobalFilter: true, 
        size: 300,
      },
      {
        accessorKey: "date",
        header: "Date",
        size: 100,
        enableSorting: true,
        enableGlobalFilter: true, 
      },
      {
        accessorKey: "category",
        header: "Category",
        size: 150,
        enableSorting: true,
        enableGlobalFilter: true, 
      },
      {
        accessorKey: "item_type",
        header: "Type",
        size: 150,
        enableSorting: false,
        enableGlobalFilter: true, 
      },
    ];
  }, [onEdit, onRemove]);

  const table = useMaterialReactTable({
    columns,
    data: data || [],
    state: {
      isLoading: isLoading,
      showProgressBars: isLoading,
    },
    muiSkeletonProps: {
      animation: "wave",
    },
    initialState: { 
      pagination: { pageSize: 5, pageIndex: 0 },
      showGlobalFilter: true, //show the global filter by default
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10]
    },
    enableColumnActions: false,
    enableGlobalFilterModes: true,
    positionGlobalFilter: "left",
    muiSearchTextFieldProps: {
      placeholder: "Search all employee insights",
      sx: { minWidth: "300px" },
      variant: "outlined",
    },
    enableColumnFilters: false,
    renderToolbarInternalActions: ({ table }) => (
      <Box display="flex" alignItems="center">
        <EmployeeListeningCreate onCreateNew={onCreateNew} />
        <MRT_ToggleFullScreenButton table={table} />
      </Box>
    ),
  });

  return (
    <Box mt={2}>
      <MaterialReactTable
        table={table}
      />
    </Box>
  );
};

export default EmployeeInsightsManagement;
