import React, { useState } from "react";

import { Box, Link } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import styles from "./ExpandableList.module.scss";

const ExpandableList = ({ items }) => {
  const [expanded, setExpanded] = useState(false);
  const itemCount = expanded ? items.length : 3;

  const handleExpand = () => {
    setExpanded(true);
  };

  const handleCollapse = () => {
    setExpanded(false);
  };

  return (
    <Box maxWidth="200px">
      <ul className={styles.list}>
        {items.slice(0, itemCount).map((item) => (
          <Tooltip arrow title={item.title} key={item.id}>
            <li className={styles.list_item}>{item.title}</li>
          </Tooltip>
        ))}
      </ul>
      {!expanded && items.length > 3 && (
        <Link sx={{ cursor: "pointer", fontSize: "12px", color:"blue", mb: 2 }} onClick={handleExpand}>Show More</Link>
      )}
      {expanded && (
        <Link sx={{ cursor: "pointer", fontSize: "12px", color:"blue", mb: 2 }} onClick={handleCollapse}>Show Less</Link>
      )}
    </Box>
  );
};

export default ExpandableList;
