import React from "react";

import { Box, Paper } from "@mui/material";
import JourneyCard from "components/LocationInsights/JourneyCard";
import { Outlet } from "react-router-dom";

import Filters from "./Filters/Filters";

const ExternalViewDetailLayout = () => {

  return (
    <Box p={4} bgcolor="#F6F6F6">
      <Paper sx={{ p:4 }}>
        <Filters />
      </Paper>

      <Paper sx={{ px:4, py: 2, mt: 4 }}>
        <Box my={4}>
          <Outlet />
          <JourneyCard />
        </Box>
      </Paper>
    </Box>
  );
};

export default ExternalViewDetailLayout;
