import React from "react";

import Header from "components/Header/Header";

const MainLayout = ({ children }) => {
  return (
    <section>
      <Header />
      <div className="page-content">
        {children}
      </div>
    </section>
  );
};

export default MainLayout;
