import React, { useEffect } from "react";

// import VisibilityIcon from "@mui/icons-material/Visibility";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import clsx from "clsx";
import { useSearchParams } from "react-router-dom";

const DomainSelect = ({ domains, data }) => {
  const [currentParams, setCurrentParams] = useSearchParams();
  const [selectedDomain, setSelectedDomain] = React.useState(() => currentParams.get("domain") ?? domains[0].name);

  useEffect(() => {
    if (!selectedDomain) {
      currentParams.delete("domain");
    } else {
      currentParams.set("domain", selectedDomain);
    }

    if (selectedDomain === "Attrition" && !currentParams.has("annualized")) {
      currentParams.set("annualized", "true");
    }

    if (selectedDomain === "Employee Insights" && !currentParams.has("dates")) {
      currentParams.set("dates", data?.[selectedDomain]?.[0]);
    }

    setCurrentParams(currentParams);
  }, [currentParams, selectedDomain, setCurrentParams]);

  const handleClickDomain = (domain) => {
    setSelectedDomain(domain.name);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="h6" fontWeight={800} marginBottom={4}>Insight</Typography>
      
      {domains.map((domain) => (
        <Box key={domain.name} 
          onClick={() => handleClickDomain(domain)}
          className={clsx("p-5 flex items-center rounded-xl mt-3 cursor-pointer", { "bg-[#f9f9f9]": domain.name !== selectedDomain, "border-indigo-500 border": domain.name === selectedDomain })}>
          {domain.name} {data?.[domain.name] ? ` - ${data?.[domain.name]}`: null}
          {/* {domain.is_active ? <VisibilityIcon className="ml-auto"/> : null} */}
        </Box>
      ))}
      
    </Box>
  );
};

export default DomainSelect;
