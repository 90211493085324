export function titleize(slug) {
  var words = slug.split("-");
  return words.map(function(word) {
    return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
  }).join(" ");
}

export function slugify(title) {
  return title.toLowerCase().replace(/\s+/g, "-");
}
