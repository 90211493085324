import { useState, useEffect } from "react";

import { Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

const HeadCountDates = () => {
  const [dates, setDates] = useState(dayjs().startOf("month", "day"));
  const [currentParams, setCurrentParams] = useSearchParams();

  useEffect(() => {
    if(dates.length === 0) {
      currentParams.delete("dates");
    } else {
      currentParams.set("dates", dates.format("YYYY-MM-DD"));
    }
    setCurrentParams(currentParams);
  }, [dates]);

  return <Box display="flex" gap={4} my={2}>
    <DatePicker
      label="HeadCount as of"
      value={dates}
      onChange={(newValue) => setDates(dayjs(newValue))}
      minDate={dayjs().startOf("month").subtract(12, "months")}
      maxDate={dayjs().startOf("month", "day")}
      shouldDisableDate={(day) => {
        return !(dayjs().isSame(day, "day") || day.isSame(day.startOf("month"), "day") || day.isSame(day.endOf("month"), "day"));
      }}
    />
  </Box>;
};

export default HeadCountDates;
