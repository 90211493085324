import React from "react";

import { Typography } from "@mui/material";
import Information from "components/LocationInsights/Information";
import RatingBarChart from "components/LocationInsights/RatingBarChart";

import { axisLabels, labels } from "./label";


const ExternalCostOfLabor = ({ data }) => {
  return (
    <>
      <Typography variant="h4" fontWeight="bold" my={4}>
        Relative Compensation - Market Benchmarks
      </Typography>
      <Information>
        This value is derived by normalizing TalentNeuron's median compensation for this role across all locations and categorizing the resulting score.
        <br /><br />        
      </Information>
      {data ? (
        <RatingBarChart data={data} labels={labels} axisLabels={axisLabels}/>
      ) : (
        <Typography variant="body1" textAlign="center">No data available</Typography>
      )}
    </>
  );
};

export default ExternalCostOfLabor;
