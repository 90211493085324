import React from "react";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Rating } from "@mui/material";
import dayjs from "dayjs";

const FeedbackDetail = ({
  feedback,
  onClose
}) => {
  if(!feedback) return null;
  return (
    <Dialog
      open={!!feedback}
      onClose={onClose}
      fullWidth="md"
      maxWidth="md"
    >
      <DialogTitle variant="h3" fontWeight="bold" sx={{ my: 4, mx: 2 }}>Feedback from {feedback?.added_by}</DialogTitle>
      <DialogContent>
        <Box sx={{ py: 4, px: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Category:
            </Grid>
            <Grid item xs={8}>
              {feedback.category}
            </Grid>
            <Grid item xs={4}>
              Product:
            </Grid>
            <Grid item xs={8}>
              <ul>
                {feedback.product.map((product) => (
                  <li key={product} style={{ marginTop: "4px", marginBottom: "4px" }}>{product}</li>
                ))}
              </ul>
            </Grid>
            <Grid item xs={4}>
              Feedback:
            </Grid>
            <Grid item xs={8}>
              {feedback.feedback}
            </Grid>
            <Grid item xs={4}>
              Urgent:
            </Grid>
            <Grid item xs={8}>
              {feedback.urgent ? "Yes" : "No"}
            </Grid>
            {feedback.category === "UI Feedback" && (
              <>
                <Grid item xs={4}>
                  Satisfaction:
                </Grid>
                <Grid item xs={8}>
                  <Rating value={feedback.satisfaction} readOnly />
                </Grid>
              </>
            )}
            <Grid item xs={4}>
              Created On:
            </Grid>
            <Grid item xs={8}>
              {dayjs(feedback.added_on).format("YYYY-MM-DD")}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDetail;
