import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import Information from "components/LocationInsights/Information";
import OneLineStackedBarChart from "components/LocationInsights/OneLineStackedBarChart";
import MoreInformationPopover from "components/MoreInformationPopover/MoreInformationPopover";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useLazyGetExternalLocationInsightsQuery } from "store/slices/locationSlice";

import Question from "../../../components/Question";
import helpText from "../helpText";

const DiversityDetail = () => {
  const [currentParams] = useSearchParams();
  const [getLocationDetail] = useLazyGetExternalLocationInsightsQuery();
  const { externalData, isLoading } = useSelector((state) => state.location);

  useEffect(() => {
    const params = Object.fromEntries(currentParams.entries());
    getLocationDetail({ params });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParams]);

  if(isLoading) {
    return (
      <Box className="flex items-center justify-center w-full">
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }

  if(!externalData || externalData.length !== 1) {
    return (
      <Box mt={20}>
        <Typography variant="body1" textAlign="center" fontWeight="bold" fontSize="24px">
          No data available. Try selecting or changing the location and job title filters
        </Typography>
      </Box>
    );
  } 

  const genderDiversityData = externalData[0].details_genderDiversity ? Object.entries(externalData[0].details_genderDiversity).reduce((acc, [key, value]) => ({ ...acc, [key]: +value }), {}) : null;
  const ethicDiversityData = externalData[0].details_ethnicityDiversity ? Object.entries(externalData[0].details_ethnicityDiversity).reduce((acc, [key, value]) => ({ ...acc, [key]: +value }), {}) : null;

  return (
    <div>
      <Question text="What is the diversity of talent in this location?" />
      <Information>        
        <MoreInformationPopover helpText={helpText.diversity}/>
      </Information>
      <Grid container spacing={4} mt={2}>
        {/* <Grid item lg={4}>
          <Box>
            <Typography variant="h4" fontWeight="bold" mt={4} mb={4}>
              Diversity
            </Typography>
            <Box textAlign="center" border="1px solid grey" py={4}>
              <Typography variant="body1" fontWeight="bold" fontSize="32px">High</Typography>
            </Box>
          </Box>
        </Grid>
         */}
        {/* <Grid item lg={8}></Grid>
         */}
        <Grid item lg={6}>
          <Typography variant="h4" fontWeight="bold" mb={4} mt={8}>
        Gender Diversity
          </Typography>
          {genderDiversityData ? (
            <OneLineStackedBarChart
              id="gender-diversity"
              data={[genderDiversityData]} 
              dataKeys={[
                { name: "Men", key: "men" }, 
                { name: "Women", key: "women" }
              ]} 
            />
          ):(
            <Typography variant="body1" >No data available</Typography>
          )}
        </Grid>
        <Grid item lg={6}>
          <Typography variant="h4" fontWeight="bold" mb={4} mt={8}>
        Ethnicity Diversity
          </Typography>
          {ethicDiversityData ? (
            <OneLineStackedBarChart
              id="ethic-diversity"
              data={[ethicDiversityData]} 
              dataKeys={[
                { name: "Asian", key: "asian" }, 
                { name: "Black", key: "black" }, 
                { name: "Latino", key: "latino" }, 
                { name: "White", key: "white" }, 
                { name: "Other", key: "other" }
              ]} 
            />
          ):(
            <Typography variant="body1" >No data available</Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default DiversityDetail;
