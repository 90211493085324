import React, { useEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import { ColorTheme10 } from "theme/chart";

const PieChart = ({ id="pie-chart", data, valueKey, labelKey, colorTheme }) => {
  useEffect(() => {
    // Create chart instance
    let root = am5.Root.new(id);
    root._logo.dispose();

    root.setThemes([
      (colorTheme || ColorTheme10).new(root)
    ]);

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        radius: am5.percent(80)
      })
    );

    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: valueKey,
        categoryField: labelKey,
      })
    );

    series.slices.template.setAll({
      stroke: am5.color(0xffffff),
      strokeWidth: 2
    });
    
    series.data.setAll(data);

    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      marginTop: 15,
      marginBottom: 15
    }));

    legend.data.setAll(series.dataItems);

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id={id} style={{ width: "100%", height: "400px" }}></div>;
};

export default PieChart;
