import React, { useEffect } from "react";

import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { Box, Grid, Typography } from "@mui/material";
import Information from "components/LocationInsights/Information";
import MoreInformationPopover from "components/MoreInformationPopover/MoreInformationPopover";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useLazyGetExternalLocationInsightsQuery } from "store/slices/locationSlice";

import HiringDifficultyStatus from "./HiringDifficultyStatus";
import OtherLocations from "./OtherLocations";
import OtherLocationsHiringDifficulty from "./OtherLocationsHiringDifficulty";
import PostingDuration from "./PostingDuration";
import Question from "../../../components/Question";
import helpText from "../helpText";

const HiringDifficultyDetail = () => {
  const [currentParams] = useSearchParams();
  const [getLocationDetail] = useLazyGetExternalLocationInsightsQuery();
  const { externalData, isLoading } = useSelector((state) => state.location);

  useEffect(() => {
    const params = Object.fromEntries(currentParams.entries());
    getLocationDetail({ params });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParams]);

  if(isLoading) {
    return (
      <Box className="flex items-center justify-center w-full">
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }

  if(!externalData || externalData.length !== 1) {
    return (
      <Box mt={20}>
        <Typography variant="body1" textAlign="center" fontWeight="bold" fontSize="24px">
          No data available. Try selecting or changing the location and job title filters
        </Typography>
      </Box>
    );
  } 

  const data = externalData[0];
  const hireDifficultyData = data.hiringDifficulty && data.score_hiringDifficulty ? { value: data.hiringDifficulty, score: data.score_hiringDifficulty } : null;

  return (
    <div>
      <Question text="How difficult is it to hire in this location, and how long does it typically take?" />
      <Information>
        <b>Hiring difficulty</b> represents how difficult TalentNeuron expects it will be to fill the filtered role at the location. {" "}
        <br />
        <br />
        <MoreInformationPopover helpText={helpText.HiringDifficulty}/>
      </Information>
      <Grid container spacing={4} mt={2}>
        <Grid item lg={6}>
          <Box>
            <Typography variant="h4" fontWeight="bold" my={4}>
                Hiring Difficulty
            </Typography>
            <HiringDifficultyStatus data={hireDifficultyData} />
          </Box>
        </Grid>
        <Grid item lg={6}>
          <Box>
            <Typography variant="h4" fontWeight="bold" my={4}>
              Typical Posting Duration
            </Typography>
            <Box textAlign="center" border="1px solid grey" py={4}>
              <PersonSearchIcon sx={{ fontSize: "48px", my: 2 }}/>
              <Typography variant="body1" fontWeight="bold" fontSize="32px">{data.postingDuration}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={12} xs={12}>
          <PostingDuration data={data?.details_postingDuration} />
        </Grid>
        <Grid item lg={6}>
          <OtherLocations data={data?.details_location_ranking} />
        </Grid>
        <Grid item lg={6}>
          <OtherLocationsHiringDifficulty data={data?.otherloc_hiringDifficulty} />
        </Grid>
      </Grid>
    </div>
  );
};

export default HiringDifficultyDetail;
