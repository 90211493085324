import { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

const LineChart = ({ data, xKey, yKey, id="line-chart", height="400px", showGrid=true, xLabel, yLabel }) => {

  // This code will only run one time
  useLayoutEffect(() => {
    const root = am5.Root.new(id);
    root._logo.dispose();

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);


    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      layout: root.verticalLayout
    }));


    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    const xRenderer = am5xy.AxisRendererX.new(root, {
      minorGridEnabled: true,
      minGridDistance: 80,    
      strokeOpacity: 0.1
      // minorLabelsEnabled: true,
    });

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      baseInterval: {
        timeUnit: "month",
        count: 1
      },
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {})
    }));

    xAxis.set("minorDateFormats", {
      month: "MM",
      year: "yyyy"
    });

    if(xLabel) {
      // Add X-axis label
      xAxis.children.push(am5.Label.new(root, {
        text: xLabel,
        fontSize: 14,
        x: am5.percent(50),
        centerX: am5.percent(50),
        centerY: am5.percent(100),
      }));
    }

    const yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1
    });

    if(!showGrid) {
      yRenderer.grid.template.set("forceHidden", true);
      xRenderer.grid.template.set("forceHidden", true);
    }

    var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: yRenderer,
      tooltip: am5.Tooltip.new(root, {}),
    }));

    if(yLabel) {
      yAxis.children.unshift(am5.Label.new(root, {
        rotation: -90,
        text: yLabel,
        fontSize: 14,
        y: am5.percent(50),
        centerX: am5.percent(50),
        centerY: am5.percent(50),
        dx: -50 // Adjust this to move the label closer or further from the axis
      }));
    }


    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var series = chart.series.push(am5xy.LineSeries.new(root, {
      name: "Count",
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: yKey,
      valueXField: xKey,
      strokeWidth: 4,
      tooltip: am5.Tooltip.new(root, {
      }),
    }));

    // Actual bullet
    series.bullets.push(function () {
      var bulletCircle = am5.Circle.new(root, {
        radius: 5,
        fill: series.get("fill")
      });
      return am5.Bullet.new(root, {
        sprite: bulletCircle
      });
    });

    series.get("tooltip").label.set("text", "[bold]{name}[/]\n{valueX.formatDate()}: {valueY}");

    series.data.setAll(data);

    series.strokes.template.setAll({
      strokeWidth: 3 // Change this value to set the line width
    });

    // Set tick label size for X axis
    xAxis.get("renderer").labels.template.setAll({
      fontSize: 12 
    });

    // Set tick label size for Y axis
    yAxis.get("renderer").labels.template.setAll({
      fontSize: 12 
    });



    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id={id} style={{ width: "100%", height }}></div>;
};
export default LineChart;
