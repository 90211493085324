import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import { insightApi } from "../../apis/api";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const feedbackApi = insightApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllFeedbacks: builder.query({
      query: () => ({
        url: "/user-feedback",
        params: {
          token: Cookies.get("storedAccessToken")
        },
      }),
      providesTags: ["Feedbacks"]
    }),
    getFeedback: builder.query({
      query: ({ id }) => ({
        url: "/user-feedback",
        params: {
          token: Cookies.get("storedAccessToken"),
          id
        },
      }),
    }),
    postFeedback: builder.mutation({
      query: ({ data }) => ({
        url: "/user-feedback",
        params: {
          token: Cookies.get("storedAccessToken")
        },
        data,
        method: "POST"
      }),
      invalidatesTags: ["Feedbacks"]
    }),
    updateFeedback: builder.mutation({
      query: ({ id, ext, data }) => ({
        url: "/user-feedback",
        params: {
          token: Cookies.get("storedAccessToken"),
          id,
          ext
        },
        data,
        method: "PUT"
      }),
      invalidatesTags: ["Feedbacks"]
    }),
    deleteFeedback: builder.mutation({
      query: ({ id }) => ({
        url: "/user-feedback",
        params: {
          token: Cookies.get("storedAccessToken"),
          id,
        },
        method: "DELETE"
      }),
      invalidatesTags: ["Feedbacks"]
    }),
  }),
});

export const feedbackSlice = createSlice({
  name: "user",
  initialState,
});

export const { 
  useUpdateFeedbackMutation, 
  usePostFeedbackMutation, 
  useDeleteFeedbackMutation,
  useGetAllFeedbacksQuery,
  useGetFeedbackQuery,
  useLazyGetFeedbackQuery
} = feedbackApi;
