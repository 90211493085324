import React, { useState } from "react";

import { Box, Grid, Typography } from "@mui/material";
import FeedbackModal from "components/Header/FeedbackModal";

import footerImage from "../../img/how-it-works-image@2x.jpg";

const Footer = () => {
  const [open, setOpen] = useState(false);
  return (
    <Box component="footer">
      <Box backgroundColor="#e7e6e6" py={6} px={4}>
        <Grid container columnSpacing={8}>
          <Grid item xs={12} md={4} sm={4}>
            <img src={footerImage} width="287" />
          </Grid>
          <Grid item xs={12} md={4} sm={4}>
            <Box>
              <Typography variant="h6" fontWeight="bold" gutterBottom>How it works</Typography>
              <Typography variant="body1" gutterBottom>
                The items you see here are custom built by Autodesk’s
                People Insights team. They are meant to answer Autodesk specific
                questions on people related topics.
              </Typography>
              <Typography variant="body1">
                Access to each dashboard is controlled based on the user
                accessing the data, so there may be some items which do not
                load any data if you click on them.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} sm={4}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>Feedback & Questions</Typography>
            <Typography variant="body1" gutterBottom>
                If you have feedback on any of the items, questions on
                specific metrics, or anything you'd like to share, we'd love to
                hear from you.
            </Typography>
            <Typography variant="body1" gutterBottom>
              <a onClick={() => setOpen(true)}>
                Share Feedback
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box backgroundColor="#222" color="#939393" py={2} px={3}>
        <Typography variant="body1">Autodesk Internal</Typography>
      </Box>
      <FeedbackModal open={open} onClose={() => setOpen(false)} />
    </Box>
  );
};

export default Footer;
