import { useEffect, useRef, useState } from "react";

import { LinearProgress, Button, Box } from "@mui/material";
import clsx from "clsx";
import { useLazyGetInsightsJobStatusQuery } from "store/slices/insightsGenieSlice";
import { downloadFile } from "utils/downloadFile";

const FETCH_STATE = {
  POLLING: "POLLING",
  ERROR: "ERROR"
};

const defaultState = {
  state: FETCH_STATE.POLLING,
  message: "Creating presentation ...",
};

const JobStatusDialog = ({ jobId, onClose }) => {
  const [getInsightsJobStatus] = useLazyGetInsightsJobStatusQuery();
  const pollingRef = useRef(null);
  const [fetchState, setFetchState] = useState(() => defaultState);

  // polling logic
  useEffect(() => {
    setFetchState(defaultState);
    if (jobId) {
      const pollJobStatus = async () => {
        try {
          const response = await getInsightsJobStatus({ job_id: jobId }).unwrap();
          if (response?.startsWith("http")) {
            downloadFile(response);
            clearInterval(pollingRef.current);
            onClose && onClose();
          }
        } catch(error) {
          setFetchState({ 
            state : FETCH_STATE.ERROR, 
            message: "Data is not available for chosen parameters. Please adjust your filters and try again."
          });
          clearInterval(pollingRef.current);
        }
      };
      pollJobStatus();
      pollingRef.current = setInterval(() => {
        pollJobStatus();
      }, 5000);
    }
   
    return () => {
      clearInterval(pollingRef.current);
    };
  }, [jobId]);

  return (
    <Box className="absolute inset-0 flex flex-col bg-white z-10 pt-16 items-center">
      <span className="text-2xl font-bold mb-8">Genie is working on it!</span>
      <Box className="w-1/2">
        {fetchState.state === FETCH_STATE.POLLING ? <LinearProgress color="primary" className="mb-2 w-full" /> : null}
        <Box className="flex items-center w-full">
          <span className={clsx("text-sm block", { "text-red-500 text-lg": fetchState.state === FETCH_STATE.ERROR })}>{fetchState.message}</span>
          {fetchState.state === FETCH_STATE.ERROR ? 
            <Button sx={{ marginLeft: "auto" }} disabled={fetchState.state !== FETCH_STATE.ERROR} onClick={() => {
              onClose && onClose();
            }} variant="outlined">Go back</Button> : null
          }
        </Box>
      </Box>
    </Box>
  );
};

export default JobStatusDialog;
