import React from "react";

import { Box, Chip, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

function getLocation(query) {
  const { city, country, geo, ...rest } = query;
  
  if(city) {
    return { city, ...rest };
  }
  if(country) {
    return { country, ...rest };
  }

  return { geo: geo || "All", ...rest };
}

const FilterChips = () => {
  const [currentParams, setCurrentParams] = useSearchParams();
  const values = Object.fromEntries(currentParams.entries());
  delete values["s"];

  const handleRemove = (key) => {
    switch (key) {
      case "division":
        currentParams.delete("division");
        currentParams.delete("l2");
        currentParams.delete("l3");
        break;
      case "l2":
        currentParams.delete("l2");
        currentParams.delete("l3");
        break;
      default:
        currentParams.delete(key);
        break;
    }
    setCurrentParams(currentParams);
  };

  const chipData = getLocation(values);

  return (
    <Box mt={2}>
      <Typography variant="h6" fontWeight="bold" display="inline" mr={2}>Filters:</Typography>
      {Object.entries(chipData).map(([key, value]) => (
        <Chip 
          key={key} 
          sx={{ mr: 1 }} 
          label={`${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`} 
          variant="contained" 
          onDelete={value ==="All" ? undefined : () => handleRemove(key, value)} 
        />
      ))}
    </Box>
  );
};

export default FilterChips;
