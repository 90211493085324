import React from "react";


import { Box, Typography } from "@mui/material";

import { ForecastedHeadcountChart } from "./TeamForecastedHeadcount";

const LocationForecastedHeadcount = ({ data, location, team }) => {
  return (
    <Box>
      <Typography variant="h4" fontWeight="bold" mb={4}>
        Budgeted Headcount - {team || "Team"}-Level at {location || "Location"}
      </Typography>
      {!data ? (
        <Typography variant="body1">No data available</Typography>
      ) : (
        <ForecastedHeadcountChart id="location-forecasted-headcount" data={data} />
      )}
    </Box>
  );
};

export default LocationForecastedHeadcount;
