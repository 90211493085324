import React, { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import dayjs from "dayjs";
import { MaterialReactTable, MRT_ToggleFullScreenButton, useMaterialReactTable } from "material-react-table";
import { useGetAllPersonasQuery, usePostPersonaMutation, useUpdatePersonaMutation } from "store/slices/personaSlice";

import ExpandableList from "./ExpandableList/ExpandableList";
import PersonaEditor from "./PersonaEditor";

const PersonaManagement = () => {
  const [open, setOpen] = useState(false);
  const [selectedPersona, setSelectedPersona] = useState(null);

  const { data: personas, isLoading } = useGetAllPersonasQuery();
  const [createPersona] = usePostPersonaMutation();
  const [updatePersona] = useUpdatePersonaMutation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (row) => {
    setSelectedPersona(row);
    setOpen(true);
  };

  const handleCreateNew = () => {
    setSelectedPersona(null);
    setOpen(true);
  };

  const handleCreate = async (values) => {
    return await createPersona(values).unwrap();
  };

  const handleUpdate = async (values) => {
    return await updatePersona(values).unwrap();
  };

  const columns = [
    {
      accessorKey: "actions",
      header: "Actions",
      type: "actions",
      size: 80,
      enableSorting: false,
      enableGlobalFilter: false, 
      Cell: ({ row }) => (
        <>
          <IconButton onClick={() => handleEdit(row.original)}>
            <EditOutlinedIcon />
          </IconButton>
        </>
      ),
    },
    { accessorKey: "persona_name", header: "Persona", size: 180 },
    { accessorKey: "display_name", header: "Display Name", size: 160 },
    {
      accessorKey: "last_modified_on",
      header: "Last Updated",
      size: 140,
      enableGlobalFilter: false, 
      accessorFn: (row) => dayjs(row.last_modified_on).format("MM/DD/YYYY")
    },
    {
      accessorKey: "dashboards",
      header: "Dashboards",
      size: 230,
      enableSorting: false,
      enableGlobalFilter: false, 
      Cell:({ row }) => row.original.persona_permissions?.dashboards ? (
        <ExpandableList items={row.original.persona_permissions.dashboards} />
      ) : null
    },
    {
      accessorKey: "research",
      header: "Researches",
      size: 230,
      enableSorting: false,
      enableGlobalFilter: false, 
      Cell:({ row }) => row.original.persona_permissions?.research ? (
        <ExpandableList items={row.original.persona_permissions.research} />
      ) : null
    },
    {
      accessorKey: "blog",
      header: "Blogs",
      size: 230,
      enableSorting: false,
      enableGlobalFilter: false, 
      Cell:({ row }) => row.original.persona_permissions?.blogs ? (
        <ExpandableList items={row.original.persona_permissions.blogs} />
      ) : null
    },
    {
      accessorKey: "employee_listening",
      header: "Employee Listening",
      size: 230,
      enableSorting: false,
      enableGlobalFilter: false, 
      Cell:({ row }) => row.original.persona_permissions?.emp_listening ? (
        <ExpandableList items={row.original.persona_permissions.emp_listening
        } />
      ) : null
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data: personas?.personas || [],
    state: {
      isLoading: isLoading,
      showProgressBars: isLoading,
    },
    muiSkeletonProps: {
      animation: "wave",
    },
    initialState: { 
      pagination: { pageSize: 5, pageIndex: 0 },
      showGlobalFilter: true, //show the global filter by default
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10]
    },
    enableColumnActions: false,
    enableGlobalFilterModes: true,
    positionGlobalFilter: "left",
    filterFns: {
      myCustomFilterFn: (row, id, filterValue) =>
        row.getValue(id)?.toString()?.toLowerCase()?.includes(filterValue.toLowerCase().toString()) || false,
    },
    globalFilterFn: "myCustomFilterFn", 
    muiSearchTextFieldProps: {
      placeholder: "Search all users",
      sx: { minWidth: "300px" },
      variant: "outlined",
    },
    enableColumnFilters: false,
    renderToolbarInternalActions: ({ table }) => (
      <Box display="flex" alignItems="center">
        <Button
          onClick={handleCreateNew}
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
        >
        Create New
        </Button>
        <MRT_ToggleFullScreenButton table={table} />
      </Box>
    ),
  });


  return (
    <>
      <Box mt={2}>
        <MaterialReactTable
          table={table}
        />
      </Box>
      <PersonaEditor 
        open={open} 
        onClose={handleClose} 
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        persona={selectedPersona}
        availableTreeValues={personas?.portal_items || []}
      />
    </>
  );
};

export default PersonaManagement;
