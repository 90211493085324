export const columns = [
  { 
    accessorKey: "geo",
    header: "Geo",
  },
  { 
    accessorKey: "country",
    header: "Country",
  },
  { 
    accessorKey: "city", 
    header: "City", 
  },
  {
    accessorKey: "rel_cost_labor",
    header: "Relative Compensation Cost",
  },
  {
    accessorKey: "supply",
    header: "Talent Supply",
    muiTableBodyCellProps: {
      align: "right",
    },
  },
  {
    accessorKey: "demand",
    header: "Talent Demand",
    muiTableBodyCellProps: {
      align: "right",
    },
  },
  {
    accessorKey: "supplyDemandRatio",
    header: "Supply/Demand Ratio",
  },
  {
    accessorKey: "competitiveConcentration",
    header: "Competitive Concentration",
  },
  {
    accessorKey: "postingDuration",
    header: "Typical Posting Duration",
  },
  {
    accessorKey: "hiringDifficulty",
    header: "Hiring Difficulty",
  },
  {
    accessorKey: "country_corporate_income_taxrate",
    header: "Corporate Income Tax Rate",
    muiTableBodyCellProps: {
      align: "right",
    },
  },
  {
    accessorKey: "english_proficiency",
    header: "English Proficiency",
    muiTableBodyCellProps: {
      align: "left",
    },
  }
];
