import React from "react";

import { Box, Typography } from "@mui/material";
import ClusteredBarChart from "components/LocationInsights/ClusteredBarChart";

const OtherLocations = ({ data }) => {
  const chartData = data ? Object.entries(data).map(([key, value]) => ({
    label: key,
    value: +value
  })).sort((a, b) => b.value -a.value).slice(0, 15) : null;

  return (
    <Box>
      <Typography variant="h4" fontWeight="bold" my={4}>
        Other Locations Talent Volume
      </Typography>
      {!data ? (
        <Typography variant="body1">No data available</Typography>
      ) : (
        <ClusteredBarChart
          id="other-locations"
          labelKey="label"
          data={chartData}
          valueKey="value"
          valueLabel="Supply Count"
        />
      )}
    </Box>
  );
};

export default OtherLocations;
