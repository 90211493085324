import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import { insightApi } from "../../apis/api";

const initialState = {
  data: null,
  externalData: null,
  isLoading: false,
  error: null,
};

export const locationApi = insightApi.injectEndpoints({
  endpoints: (builder) => ({
    getLocationData: builder.query({
      query: ({ role }) => ({
        url: "/location-insights",
        params: {
          token: Cookies.get("storedAccessToken"),
          job_code: role
        }
      }),
    }),
    getLocationDetail: builder.query({
      query: ({ id }) => ({
        url: "/location-details",
        params: {
          token: Cookies.get("storedAccessToken"),
          id
        }
      }),
    }),
    getLocationTeams: builder.query({
      query: () => ({
        url: "/location-insights-teams",
        params: {
          token: Cookies.get("storedAccessToken")
        }
      }),
    }),
    getLocationInsights: builder.query({
      query: ({ params }) => ({
        url: "/location-insights-metrics",
        params: {
          token: Cookies.get("storedAccessToken"),
          ...params
        }
      }),
    }),
    getLocationTeamDetails: builder.query({
      query: ({ locationId, teamId }) => ({
        url: "/location-insights-team-details",
        params: {
          token: Cookies.get("storedAccessToken"),
          location_id: locationId,
          team_id: teamId
        }
      }),
    }),
    getExternalLocationInsights: builder.query({
      query: ({ params }) => ({
        url: "/location-market-details",
        params: {
          token: Cookies.get("storedAccessToken"),
          ...params
        }
      }),
    }), 
    getLocationFilters: builder.query({
      query: () => ({
        url: "/location-insights-filters",
        params: {
          token: Cookies.get("storedAccessToken")
        }
      }),
    }),
    getTimezoneDistribution: builder.mutation({
      query: ({ baseTimezone, data }) => ({
        url: "/location-insights-timezones",
        method: "POST",
        params: {
          token: Cookies.get("storedAccessToken"),
          baseline: baseTimezone,
        },
        data
      }),
    }),
    getLocationOfficeSummary: builder.query({
      query: () => ({
        url: "/location-insights-office-summary",
        params: {
          token: Cookies.get("storedAccessToken")
        }
      }),
    }),
    getLocationOfficeDetail: builder.query({
      query: ({ id }) => ({
        url: "/location-insights-office-detail",
        params: {
          token: Cookies.get("storedAccessToken"),
          id: id
        }
      }),
    }),
    getLocationInsightsHelp: builder.query({
      query: () => ({
        url: "/location-insights-help",
        params: {
          token: Cookies.get("storedAccessToken"),
        }
      }),
    }),
    getAdditionalResources: builder.query({
      query: ({ params }) => ({
        url: "/additional-resources",
        params: {
          token: Cookies.get("storedAccessToken"),
          ...params
        }
      }),
    }),
    searchPortal: builder.query({
      query: ({ params }) => ({
        url: "/search",
        params: {
          token: Cookies.get("storedAccessToken"),
          ...params
        }
      }),
    }),
  }),
});

export const locationSlice = createSlice({
  name: "location",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      locationApi.endpoints.getLocationInsights.matchPending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addMatcher(
      locationApi.endpoints.getLocationInsights.matchFulfilled,
      (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }
    );
    builder.addMatcher(
      locationApi.endpoints.getLocationInsights.matchRejected,
      (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      }
    );
    builder.addMatcher(
      locationApi.endpoints.getExternalLocationInsights.matchPending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addMatcher(
      locationApi.endpoints.getExternalLocationInsights.matchFulfilled,
      (state, action) => {
        state.isLoading = false;
        state.externalData = action.payload;
      }
    );
    builder.addMatcher(
      locationApi.endpoints.getExternalLocationInsights.matchRejected,
      (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      }
    );
  },
  
});

export const { 
  useGetLocationDataQuery, 
  useGetLocationDetailQuery, 
  useLazyGetLocationDataQuery, 
  useGetLocationTeamsQuery,
  useGetLocationFiltersQuery,
  useGetLocationTeamDetailsQuery,
  useGetLocationInsightsQuery,
  useLazyGetLocationInsightsQuery,
  useLazyGetExternalLocationInsightsQuery,
  useGetTimezoneDistributionMutation,
  useGetLocationOfficeSummaryQuery,
  useLazyGetLocationOfficeSummaryQuery,
  useGetLocationOfficeDetailQuery,
  useLazyGetLocationOfficeDetailQuery,
  useLazyGetAdditionalResourcesQuery,
  useGetAdditionalResourcesQuery,
  useGetLocationInsightsHelpQuery,
  useLazySearchPortalQuery
} = locationApi;
