import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { ColorTheme1 } from "theme/chart";


const ClusteredBarChart = ({ id="clustered-bar-chart", height="800px", labelKey, data, valueKey, valueLabel, xLabel, yLabel }) => {
  
  // This code will only run one time
  useLayoutEffect(() => {
    const root = am5.Root.new(id);
    root._logo.dispose();

    root.setThemes([
      ColorTheme1.new(root)
    ]);
    
    
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      layout: root.verticalLayout
    }));
    
    
    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    var legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50
    }));
    
    
    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const yRenderer = am5xy.AxisRendererY.new(root, {
      inversed: true,
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
    });
    yRenderer.labels.template.set("forceHidden", true);
    yRenderer.grid.template.set("forceHidden", true);

    var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: labelKey,
      renderer: yRenderer,
      min: 0
    }));

    if(yLabel) {
      yAxis.children.unshift(am5.Label.new(root, {
        rotation: -90,
        text: yLabel,
        y: am5.percent(50),
        centerX: am5.percent(50),
        centerY: am5.percent(50),
        dx: -50 // Adjust this to move the label closer or further from the axis
      }));
    }
    
    yAxis.data.setAll(data);

    const xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.1
    });
    xRenderer.labels.template.set("forceHidden", true);
    xRenderer.grid.template.set("forceHidden", true);
    
    var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: xRenderer,
      extraMax: 1,
    }));

    if(xLabel) {
      // Add X-axis label
      xAxis.children.push(am5.Label.new(root, {
        text: xLabel,
        x: am5.percent(50),
        centerX: am5.percent(50),
        centerY: am5.percent(100),
      }));
    }
    
    
    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createSeries(field, name) {
      var series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: field,
        categoryYField: labelKey,
        sequencedInterpolation: true,
      }));
    
      series.columns.template.setAll({
        height: am5.p100,
        tooltipY: am5.percent(10),
        tooltipText: `[bold]{categoryY}[/]\n${valueLabel}: {valueX}`
      });
    
    
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 1,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            centerY: am5.p50,
            text: `{${labelKey}}`,
            populateText: true,
          })
        });
      });
    
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 1,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            centerX: am5.p100,
            centerY: am5.p50,
            text: "{valueX}",
            fill: am5.color(0xffffff),
            populateText: true,
          })
        });
      });
    
      series.data.setAll(data);
      series.appear();
    
      return series;
    }
    
    createSeries(valueKey, valueLabel);

    
    legend.data.setAll(chart.series.values);

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id={id} style={{ width: "100%", height }}></div>;
};

export default ClusteredBarChart;
