import React from "react";

import * as am5 from "@amcharts/amcharts5";
import { Typography } from "@mui/material";
import RatingBarChart from "components/LocationInsights/RatingBarChart";

const labels = [
  {
    category: "1",
    label: "Slightly Difficult",
    value: 50,
    columnSettings: {
      fill: am5.color(0x88A943)
    }
  }, 
  {
    category: "2",
    label: "Slightly Difficult",
    value: 50,
    columnSettings: {
      fill: am5.color(0x88A943)
    }
  }, 
  {
    category: "3",
    label: "Moderately Difficult",
    value: 50,
    columnSettings: {
      fill: am5.color(0xdbae03)
    }
  }, 
  {
    category: "4",
    label: "Moderately Difficult",
    value: 50,
    columnSettings: {
      fill: am5.color(0xdbae03)
    }
  }, 
  {
    category: "5",
    label: "Difficult",
    value: 50,
    columnSettings: {
      fill: am5.color(0xff6b00)
    }
  }, 
  {
    category: "6",
    label: "Difficult",
    value: 50,
    columnSettings: {
      fill: am5.color(0xff6b00)
    }
  }, 
  {
    category: "7",
    label: "Very Difficult",
    value: 50,
    columnSettings: {
      fill: am5.color(0xba413a)
    }
  },
  {
    category: "8",
    label: "Very Difficult",
    value: 50,
    columnSettings: {
      fill: am5.color(0xba413a)
    }
  },
  {
    category: "9",
    label: "Very Difficult",
    value: 50,
    columnSettings: {
      fill: am5.color(0xba413a)
    }
  },
  {
    category: "10",
    label: "Very Difficult",
    value: 50,
    columnSettings: {
      fill: am5.color(0xba413a)
    }
  }
];

const axisLabels = [
  { category: "1", text: "Slightly Difficult" },
  { category: "10", text: "Very Difficult" }
];

const HiringDifficultyStatus = ({ data }) => {
  return (
    <div>
      {data ? (
        <RatingBarChart data={data} labels={labels} axisLabels={axisLabels} />
      ) : (
        <Typography variant="body1" textAlign="center">No data available</Typography>
      )}
    </div>
  );
};

export default HiringDifficultyStatus;


