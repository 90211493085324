const position = { x: 0, y: 0 };
const edgeType = "smoothstep";
const redToGreen = ["#ff0000", "#db1200", "#b62500", "#923700", "#6d4900", "#495b00", "#246e00", "#008000" ];

export const getDecisionTree = (decisionTree) => {
  const nodes = [];
  const edges = [];
  let i = 0;
  const addNode = (node) => {
    const condition = Math.round(node.split_condition);
    nodes.push({
      id: `${node.nodeid}`,
      data: { 
        ...node,
        ...(node.split_condition ? ({ split_condition: condition }) : {}),
        ...(node.split_condition ? {} : ({ bgColor: redToGreen[i] }) ) 
      },
      type: node.split ? "glint" : "trust",
      position
    });
    if(!node.split) {
      i++;
    }
    if (node.children) {
      node.children.sort((a, b) => a.leaf - b.leaf);
      node.children.forEach((child) => {
        edges.push({
          id: `e${node.nodeid}-${child.nodeid}`,
          source: node.nodeid.toString(),
          target: child.nodeid.toString(),
          type: edgeType,
          label: node.yes === child.nodeid ? `<${condition}` : `${condition === 100 ? "=100" : `>${condition}`}`,
          animated: true,
          labelStyle: {
            fontSize: "18px"
          }
        });
        addNode(child);
      });
    }
  };
  decisionTree.forEach((node) => addNode(node, 0));
  return { nodes, edges };
};
