import React, { useLayoutEffect, useState } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Box, Link, Typography } from "@mui/material";
import { ColorTheme1 } from "theme/chart";

const ActiveEmployerRanking = ({ data }) => {
  const [showAll, setShowAll] = useState(false);

  const updatedData = data ? data.map((item) => ({
    label: item.name,
    demand: +item.demandCount
  })) : [];

  const chartData = showAll ? updatedData : updatedData.slice(0, 10);
  
  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Typography variant="h4" fontWeight="bold" my={4}>
        Active Employer Ranking
        </Typography>
        {data && (data.length > 10) && ( 
          <Link sx={{ color: "blue", cursor: "pointer", ml: 4 }} onClick={handleShowAll}>
            {showAll ? "Show less" : "Show all"}
          </Link>
        )}
      </Box>
      {!data ? (
        <Typography variant="body1">No data available</Typography>
      ) : (
        <ActiveEmployerRankingChart id="top-locations" data={chartData} />
      )}
    </Box>
  );
};

export default ActiveEmployerRanking;

const ActiveEmployerRankingChart = ({ id, data }) => {

  // This code will only run one time
  useLayoutEffect(() => {
    const root = am5.Root.new(id);
    root._logo.dispose();

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      ColorTheme1.new(root)
    ]);


    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      layout: root.verticalLayout
    }));
    
    
    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    var legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50
    }));
    
    
    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const yRenderer = am5xy.AxisRendererY.new(root, {
      inversed: true,
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
    });
    // yRenderer.labels.template.set("forceHidden", true);
    yRenderer.grid.template.set("forceHidden", true);

    var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "label",
      renderer: yRenderer,
      min: 0
    }));
    
    yAxis.data.setAll(data);

    const xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.1
    });
    xRenderer.labels.template.set("forceHidden", true);
    xRenderer.grid.template.set("forceHidden", true);
    
    var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: xRenderer,
      extraMax: 0.1
    }));
    
    
    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createSeries(field, name) {
      var series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: field,
        categoryYField: "label",
        sequencedInterpolation: true,
      }));
    
      series.columns.template.setAll({
        // height: am5.p50,
      });
    
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 1,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            centerX: am5.p0,
            centerY: am5.p50,
            text: "{valueX}",
            fill: am5.color(0x000),
            populateText: true
          })
        });
      });
    
      series.data.setAll(data);
      series.appear();
    
      return series;
    }
    
    createSeries("demand", "Demand");

    
    legend.data.setAll(chart.series.values);

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id={id} style={{ width: "100%", height: `${data.length * 50}px` }}></div>;
};
