import React from "react";

import { Link } from "react-router-dom";

import styles from "./DashboardTile.module.scss";

const DashboardTile = ({ comingSoon, link, image, description, title, isExternal }) => {
  return isExternal ? 
    <a className={`${styles.tile} ${comingSoon ? styles.coming_soon : ""}`} target="_blank" href={link} rel="noreferrer">
      <div className={styles.image_container}>
        <img src={image} alt={title} />
      </div>
      <div className="mt-5">
        <h4 className="text-left">{title}</h4>
        {comingSoon && <p className="text-center">In Development</p>}
        <p className="text-left text-[8px]">
          {description?.substring(0, 250)}
          {description.length > 250 ? "..." : ""}
        </p>
      </div>
    </a>
    :(
      <Link
        className={`${styles.tile} ${comingSoon ? styles.coming_soon : ""}`}
        to={comingSoon ? "#" : `/dashboard/${link}`}
      >
        <div className={styles.image_container}>
          <img src={image} alt={title} />
        </div>
        <div className="mt-5">
          <h4 className="text-left">{title}</h4>
          {comingSoon && <p className="text-center">In Development</p>}
          <p className="text-left text-[8px]">
            {description?.substring(0, 250)}
            {description.length > 250 ? "..." : ""}
          </p>
        </div>
      </Link>
    );
};

export default DashboardTile;
