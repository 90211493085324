import React, { useState } from "react";

import { Box, Modal } from "@mui/material";

const Philosophy = () => {
  const [open, setOpen] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    borderRadius: "7px",
    boxShadow: 24,
    p: 4,
  };
  const formCloseHandler = () => {
    setOpen(false);
  };
  return (
    <>
      <div>
        <div
          onClick={() => setOpen(true)}
          className="hover:text-sky-800 cursor-pointer"
        >
          Research Philosophy
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => formCloseHandler()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <section className="flex flex-col gap-3 items-end">
            <button
              className=" px-3 py-1 hover:bg-red-700 ease-in-out duration-500 rounded-md bg-red-500 text-white"
              onClick={formCloseHandler}
            >
              close
            </button>
            <div className="leading-6 font-semibold flex flex-col gap-3">
              <p>
                Our People Insights research philosophy is grounded in the use of rigorous scientific methods to address important questions about Autodesk’s workforce, talent processes, and company culture. We believe that by investigating these issues deeply and with complex, multi-source datasets, we can inform and enhance the well-being and performance of both Autodesk and our employees.
              </p>
              <p>
                Our team explores new and challenging research topics that have the potential to generate actionable insights that improve the employee experience at Autodesk. We understand that some of these topics may not yield significant or conclusive results, but we see them as valuable learning opportunities and sources of feedback.
              </p>
              <p>
                We are fully committed to conducting ethical and responsible research that respects the privacy of our participants and stakeholders. We adhere to the highest standards of quality and integrity in our data collection, analysis, and reporting, and we strive to communicate our research findings clearly and transparently to our audiences and collaborators.
              </p>
              <p>
                We look forward to bringing meaningful and actionable research insights to you via this People Insights Portal.
              </p>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
};

export default Philosophy;
