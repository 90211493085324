import React from "react";

import { Box, Link, Typography } from "@mui/material";
import LocationInsightCard from "components/LocationInsights/LocationInsightCard";
import { useParams, useSearchParams, Link as RouterLink } from "react-router-dom";

const TimezoneOverview = ({ data }) => {
  const commonTimezone = data?.value?.common_tz;
  const commonTimezonePercent = data?.value?.common_tz_pct;
  const locations = data?.value?.locations;
  const [currentParams] = useSearchParams();
  const { id } = useParams();

  return (
    <LocationInsightCard title="Location Time Zone" data={data}>
      <Box height="100%" display="flex" flexDirection="column">        
        <Box p={4} border="1px solid grey" flex="1">
          <Typography variant="body2" mb={4} color="grey" fontSize="24px" fontWeight="bold" textAlign="center">
            The most common time zone, with 
            <b> {Math.round(commonTimezonePercent)}%</b> of workers is
          </Typography>
          <Typography textAlign="center" fontWeight="bold" fontSize="30px" variant="body1" mb={4}>{commonTimezone}</Typography>
          <Typography variant="body2" mb={4} color="grey" fontSize="24px" fontWeight="bold" textAlign="center">based in locations like: {locations?.slice(0, 2).join(", ")}</Typography>
        </Box>
        <Box my={2}>
          <Link 
            component={RouterLink} 
            sx={{ cursor: "pointer", fontSize: "16px", fontWeight: "bold", color:"blue", mb: 2 }} 
            to={`/dashboard/${id}/location-insights/internal-view/detail/timezone?${currentParams.toString()}`}>
              View Team Time Zone Details
          </Link>
        </Box>
      </Box>
    </LocationInsightCard>
  );
};

export default TimezoneOverview;
