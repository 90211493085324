import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import { insightApi } from "../../apis/api";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const employeeListeningApi = insightApi.injectEndpoints({
  endpoints: (builder) => ({
    getImageUploadUrl: builder.query({
      query: () => ({
        url: "/secure-url"
      }),
    }),
    uploadImage: builder.mutation({
      query: ({ data, url }) => ({
        preferredBaseUrl: url,
        data,
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }),
    }),
    getAllEmployeeListenings: builder.query({
      query: () => ({
        url: "/employee-listening",
        params: {
          token: Cookies.get("storedAccessToken"),
        },
      }),
      providesTags: ["EmployeeListenings"]
    }),
    getEmployeeListening: builder.query({
      query: ({ id }) => ({
        url: "/employee-listening",
        params: {
          token: Cookies.get("storedAccessToken"),
          id
        },
      }),
    }),
    createEmployeeListening: builder.mutation({
      query: ({ ext, data }) => ({
        url: "/employee-listening",
        params: {
          token: Cookies.get("storedAccessToken"),
          ext
        },
        data,
        method: "POST",
      }),
      invalidatesTags: ["EmployeeListenings"]
    }),
    updateEmployeeListening: builder.mutation({
      query: ({ id, ext, data }) => ({
        url: "/employee-listening",
        params: {
          token: Cookies.get("storedAccessToken"),
          id,
          ext
        },
        data,
        method: "PUT",
      }),
      invalidatesTags: ["EmployeeListenings"]
    }),
    deleteEmployeeListening: builder.mutation({
      query: ({ id }) => ({
        url: "/employee-listening",
        params: {
          token: Cookies.get("storedAccessToken"),
          id
        },
        method: "DELETE",
      }),
      invalidatesTags: ["EmployeeListenings"]
    }),
    updateEmployeeListeningOrder: builder.mutation({
      query: ({ data }) => ({
        url: "/employee-listening",
        params: {
          token: Cookies.get("storedAccessToken"),
        },
        data,
        method: "PUT",
      }),
      invalidatesTags: ["EmployeeListenings"]
    }),
  }),
});

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
});

export const {
  useUploadImageMutation,
  useLazyGetImageUploadUrlQuery, 
  useGetAllEmployeeListeningsQuery, 
  useGetEmployeeListeningQuery,
  useCreateEmployeeListeningMutation,
  useUpdateEmployeeListeningMutation,
  useDeleteEmployeeListeningMutation,
  useLazyGetEmployeeListeningQuery,
  useUpdateEmployeeListeningOrderMutation,
} = employeeListeningApi;
