import React, { useCallback, useState } from "react";

import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { Box, Typography } from "@mui/material";
import {
  applyNodeChanges,
  ReactFlow,
} from "@xyflow/react";

import { HeaderNode, SourceNode, TargetNode } from "./Nodes";
import { createEdges, getTopLocationsData } from "./utils";

const nodeTypes = { autodesk: SourceNode, team: TargetNode, header: HeaderNode };

const TopLocations = ({ autodeskData, teamData }) => {
  const { autodeskData: autodeskChartData, teamData: teamChartData } = getTopLocationsData(autodeskData, teamData);

  const autodeskNodes = autodeskChartData.map((item, index) => ({
    id: `${item.category}-source`,
    position: { x: 0, y: 100 * (index + 1) },
    data: { label: item.category, value: item.value, color: item.color },
    type: "autodesk",
    width: 700,
  }));

  const teamNodes = teamChartData.map((item, index) => ({
    id: `${item.category}-target`,
    position: { x: 1000, y: 100 * (index + 1) },
    data: { label: item.category, value: item.value, color: item.color },
    type: "team",
    width: 700,
  }));

  const headerNodes = [
    { id: "autodesk-header", position: { x: 60, y: 0 }, data: { label: "FY25 Top Locations for All TBHs across Autodesk" }, type: "header" },
    { id: "team-header", position: { x: 1200, y: 0 }, data: { label: " FY25 Top Location for Team's TBHs" }, type: "header" },
  ];

  const initialNodes = [...autodeskNodes, ...teamNodes, ...headerNodes ]; 
  const initialEdges = createEdges(autodeskChartData, teamChartData);


  return (
    <Box>
      <Typography variant="h4" fontWeight="bold">
            FY25 Top Locations
      </Typography>
      <Typography variant="body1" mb={2}>
        <LightbulbOutlinedIcon sx={{ mr: 1 }} />
        Match the colors across both charts to find common top TBH locations between Autodesk and Team. Grey bars signify unmatched locations. Dive into the details with a glance! 
      </Typography>
      <TopLocationsChart initialNodes={initialNodes} initialEdges={initialEdges} />
    </Box>
  );
};

export default TopLocations;


const TopLocationsChart = ({ initialNodes }) => {
  const [nodes, setNodes] = useState(initialNodes);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [],
  );

  return (
    <Box width="100%" height="800px">
      <ReactFlow
        nodes={nodes}
        onNodesChange={onNodesChange}
        fitView
        nodesDraggable={false}
        zoomOnScroll={false}
        panOnDrag={false}
        zoomOnPinch={false}
        zoomOnDoubleClick={false}
        nodeTypes={nodeTypes}
        preventScrolling={true}
      />
    </Box>
  );
};
