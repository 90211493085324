import React, { useState } from "react";

import { Typography, Box, Grid, Button, Link } from "@mui/material";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { slugify } from "utils/slug";

import classNames from "./LifecycleSurveys.module.scss";

const LifecycleSurveys = ({ items }) => {
  return (
    <Box py={4}>
      {items.length === 0 ? (
        <Typography variant="body1" textAlign="center">No Employee Experience Listening</Typography>
      ) : (
        <LifecycleSurveyContent items={items.sort((a, b) => Number(a.display_order) - Number(b.display_order))} />
      )}
    </Box>
  );
};

export default LifecycleSurveys;

const LifecycleSurveyContent = ({ items }) => {
  const [selectedSurvey, setSelectedSurvey] = useState(items[0]);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSelectSurvey = (survey) => {
    if(survey.coming_soon) return;
    setSelectedSurvey(survey);
  };

  const handleGotoInsights = () => {
    searchParams.set("tab", 1);
    setSearchParams(searchParams);
  };

  return (
    <Grid container>
      <Grid xs={5}>
        <Box className={classNames.survey}>
          <Box>
            {items.map((item) => (
              <StepButton key={item.id} isActive={item.id === selectedSurvey?.id} isDisabled={item.coming_soon} fullWidth onClick={() => handleSelectSurvey(item)}>
                {item.pageName}
              </StepButton>
            ))}
          </Box>
        </Box>
        <Box mt={-2}>
          <StepButton variant="contained" color="secondary" fullWidth onClick={handleGotoInsights}>Employee Insights(Glint)</StepButton>
        </Box>
      </Grid>
      <Grid xs={7}>
        {selectedSurvey && (
          <Box px={8} mt={4}>
            <Typography variant="h1" fontWeight="bold" mb={4}>{selectedSurvey.title}</Typography>
            <Typography variant="body1" fontSize={20}>{selectedSurvey.description}</Typography>
            
            <Box display="flex" mt={4} mb={2}>
              <Box flex={1}>
                <Typography variant="h3" fontWeight="bold" mb={2}>Key Question</Typography>
                <Typography variant="body1" fontSize={20}>{selectedSurvey.question}</Typography>
              </Box>
              <Box>
                <Box border="1px solid black" px={8} py={6} display="flex" alignItems="center" justifyContent="center">
                  <Box>
                    <Typography variant="h5" fontWeight="bold" mb={2} fontSize="30px" textAlign="center">Score</Typography>
                    <Typography variant="body1" textAlign="center" fontWeight="bold" fontSize={24}> {selectedSurvey.score}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Link sx={{ fontSize: "24px", color: "#222222" }} component={RouterLink} to={`/employee-listening/${selectedSurvey.id}/${slugify(selectedSurvey.title)}`} variant="body1" fontWeight="bold" fontSize={18}>Click here to view the {selectedSurvey.pageName} Survey Dashboard</Link>
          </Box>
        )}
      </Grid>

      <Link to="/employee-listening/132602/ex-listening-summary" component={RouterLink}
        sx={{ color: "white", textDecoration: "none", fontSize: "24px", backgroundColor: "#f87171 ", padding: 2, cursor: "pointer" }}>
          Click here for a summary of lifecycle surveys
      </Link>
      
    </Grid>
  );
};

const StepButton = ({ children, isActive, isDisabled, onClick, ...other }) => {
  let styles = {
    color: "white",
    backgroundColor: "#878787"
  };

  if(isActive) {
    styles.backgroundColor = "#ffc219";
    styles.color = "black";
  }
  if(!isActive) styles.backgroundColor = "#6060ff";
  if(isDisabled) {
    styles.backgroundColor = "#878787";
    styles.color = "white";
  }

  return (
    <Button 
      variant="contained" 
      onClick={onClick}
      sx={{ 
        my: 2, 
        display: "block",
        textAlign: "center", 
        color: "white", 
        textTransform: "unset", 
        fontSize: "18px",
        ...styles,
        "&:hover": {
          backgroundColor: isDisabled ? "#878787" : isActive ? "#ffc219" : "#6060ff",
        },
      }}
      {...other}
    >
      {children}
    </Button>
  );
};
