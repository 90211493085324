export const palette = {
  primary: {
    main: "#000000",
    light: "#374151",
    contrastText: "#fff",
  },
  error: {
    main: "#E53529"
  }
};
