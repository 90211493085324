import { Typography } from "@mui/material";

export default {
  attrition: {
    title: "How do we calculate and classify Attrition?",
    content: (
      <>
        <Typography variant="body1" gutterBottom mb={1} fontWeight="bold">
          To calculate attrition, we count the number of terminations in a timeframe and divide it by the average headcount over the same timeframe. For example, to calculate voluntary attrition for Q1: 
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              Calculate average headcount as (headcount on February 1st + headcount on April 30th)/2
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Count the voluntary terminations that occurred between February 1 and April 30, inclusive
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Divide the count of voluntary terminations by the average headcount from step 1
            </Typography>
          </li>
        </ul>
        <Typography variant="body1">
          <br/><b>To classify attrition from Low to High,</b> we compare it to the Autodesk total attrition rate for the year. Values that are significantly lower, similar or higher are then classified as Low, Medium or High respectively. Organizations with no attrition are classified as None.
        </Typography>
      </>
    )
  },
  openBudgetedRoles: {
    title: "How do we calculate budgeted headcount?",
    content: (
      <>
        <Typography variant="body1" gutterBottom mb={1}>
          Budgeted headcount numbers are derived from Anaplan's TBH data. Specifically, we use the open TBHs for the fiscal year to estimate what the headcount may be if all the positions are hired for. 
          <b> TBH</b> (To be hired) represents roles that are intended to be filled but are not yet occupied. <b>Open, or active, requisitions</b> show how many of these TBH roles have had requisitions created in Workday.
        
          <p><br/>Note that the projection numbers do not take attrition into account.</p>
        </Typography>
      </>
    )
  },
};
