import React from "react";

import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";

import FeedbackModal from "./FeedbackModal";
import logo from "../../img/autodesk-logo-primary-rgb-white.svg";
import lock from "../../img/lock-icon.svg";

const Header = ({ pageName }) => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = React.useState(false);
  return (
    <Box component="header" backgroundColor="black" color="white" height="56px">
      <Box className="flex items-center justify-between h-14">
        <Box id="logo" className="flex justify-center items-center" to="/">
          <Link to="/" className="logo ml-10">
            <img
              src={logo}
              className="h-3 w-[140px]"
              height="13"
              alt="Autodesk logo"
            />
          </Link>
          {pageName && (
            <>
              <span className="app-title">People Insights Portal</span>
              <Box component="span" className="separator"
                sx={{
                  backgroundColor: "#6f6f6f",
                  borderRadius: "2px",
                  display: "inline-block",
                  height: "3px",
                  margin: "0 6px 2px",
                  verticalAlign: "middle",
                  width: "18px",
                }}
              ></Box>
              <span className="page-title">{pageName}</span>
            </>
          )}
        </Box>
        <Box className="flex gap-10 nav text-white mr-20">
          <Button onClick={() => setIsFeedbackModalOpen(true)} sx={{ fontWeight: "bold", textTransform: "unset" }} color="inherit">Feedback</Button>
          <span
            className="page-tag flex justify-end items-center text-sm"
            title="Confidential & Secured"
          >
            {/* Confidential */}
            <img
              className="lock-icon"
              src={lock}
              title="Confidential & Secured"
            />
          </span>
        </Box>
      </Box>
      <FeedbackModal 
        open={isFeedbackModalOpen}
        onClose={() => setIsFeedbackModalOpen(false)}
      />
    </Box>
  );
};

export default Header;
