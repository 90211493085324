import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

const InfoDialog = ({ triggerComponent, title, description }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div onClick={handleClickOpen}>
        {triggerComponent}
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className="flex items-center justify-between">
          <span className="text-xl font-semibold">{title}</span>
          <IconButton onClick={handleClose} color="primary">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="text-lg">
          {typeof description === "string" ? <p>{description}</p> : description}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InfoDialog;
