import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Box, Link } from "@mui/material";
import LocationInsightCard from "components/LocationInsights/LocationInsightCard";
import { useParams, useSearchParams, Link as RouterLink } from "react-router-dom";
import { ColorTheme2 } from "theme/chart";

const NAME_MAPPING = {
  tbh_count: "TBH",
  requisition_count: "Open Requisitions"
};

const RecruitmentOverview = ({
  data
}) => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();
  const chartData = data?.value ? [{ category: "Team", tbh_count: +data.value.tbh_count, requisition_count: +data.value.requisition_count }] : [];  
  return (
    <LocationInsightCard title="Budgeted & Open Roles" data={data?.value}>
      <RecruitmentOverviewChart data={chartData} />
      <Box mt={4}>
        <Link 
          component={RouterLink} 
          sx={{ cursor: "pointer", fontSize: "16px", fontWeight: "bold", color:"blue", mb: 2 }} 
          to={`/dashboard/${id}/location-insights/internal-view/detail/recruitment?${currentParams.toString()}`}>
            View Budgeted Roles Details
        </Link>
      </Box>
    </LocationInsightCard>
  );
};

export default RecruitmentOverview;

const RecruitmentOverviewChart = ({ data }) => {

  useLayoutEffect(() => {
    const root = am5.Root.new("budgeted-and-pen-roles");

    root.setThemes([ColorTheme2.new(root)]);
    root._logo.dispose();

    
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      paddingLeft:0,
      layout: root.verticalLayout
    }));


    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    var legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50
    }));


    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "category",
      renderer: am5xy.AxisRendererY.new(root, {
        inversed: true,
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        minorGridEnabled: true
      })
    }));

    yAxis.data.setAll(data);

    var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererX.new(root, {
        strokeOpacity: 0.1,
        minGridDistance: 50,
      }),
      min: 0,
      extraMax: 0.1
    }));

    // Hide X-axis grid lines
    xAxis.get("renderer").grid.template.set("visible", false);
    // Hide X-axis tick labels
    xAxis.get("renderer").labels.template.set("visible", false);

    // Hide Y-axis grid lines
    yAxis.get("renderer").grid.template.set("visible", false);
    // Hide Y-axis tick labels
    // yAxis.get("renderer").labels.template.set("visible", false);

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createSeries(field, name) {
      var series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: field,
        categoryYField: "category",
        sequencedInterpolation: true,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "[bold]{name}[/]\n{categoryY}: {valueX}"
        }),
        // strokeOpacity: 1,
        // fillOpacity: 0.3
      }));

      series.columns.template.setAll({
        height: am5.p100,
        // strokeOpacity: 1,
        // fillOpacity: 0.3,
        width: am5.percent(10),
        maxHeight: 50,
      });


      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 1,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            centerY: am5.p50,
            text: "{valueX}",
            populateText: true,
            fontSize: 12
          })
        });
      });

      series.data.setAll(data);
      series.appear();

      return series;
    }

    Object.entries(NAME_MAPPING).forEach(([key, value]) => {
      createSeries(key, value);
    });


    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50
    }));

    legend.data.setAll(chart.series.values);


    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "zoomY"
    }));
    cursor.lineY.set("forceHidden", true);
    cursor.lineX.set("forceHidden", true);

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id="budgeted-and-pen-roles" style={{ width: "100%", height: "400px" }}></div>;
};
