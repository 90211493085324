import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { getTeamFromQueryParams } from "../../../utils";

const TeamLevelHeadcount = ({ location, team }) => {
  const [currentParams] = useSearchParams();
  const teamName = getTeamFromQueryParams(currentParams);
  return (
    <Grid container spacing={4}>
      <Grid item lg={6} xs={12}>
        <Typography variant="h4" fontWeight="bold" mb={4}>
          {teamName}'s Headcount
        </Typography>
        {location ? (
          <Box height="200px">
            <Box display="flex">
              <Box width="160px" display="flex" justifyContent="center" flexDirection="column" border="1px solid grey" mr={2}>
                <Typography variant="h1" textAlign="center" fontSize="32px" fontWeight="bold">
                  {location["Total (excl. Assoc & OSP)"]}
                </Typography>
                <Typography variant="h6" textAlign="center" fontWeight="bold">
            Total Workers
                </Typography>
              </Box>
              <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="space-between" my={-1}>
                <Box border="1px solid grey" my={1} p={2} textAlign="center">
                  <Typography variant="body1" fontSize="24px" fontWeight="bold">
                    {location.Employees}
                  </Typography>
                  <Typography variant="body1" fontSize="16px">
              Total Employees
                  </Typography>
                </Box>
                <Box border="1px solid grey" my={1} p={2} textAlign="center">
                  <Typography variant="body1" fontSize="24px" fontWeight="bold">
                    {location["Contingents (excl. Assoc & OSP)"]}
                  </Typography>
                  <Typography variant="body1" fontSize="16px">
              Total Contingents
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Typography variant="body1">No data available</Typography>
        )}
        
      </Grid>
      <Grid item lg={6} xs={12}>
        <Typography variant="h4" fontWeight="bold" mb={4}>
          Autodesk's Headcount
        </Typography>
        {team ? (
          <Box height="200px">
            <Box display="flex">
              <Box width="160px" display="flex" justifyContent="center" flexDirection="column" border="1px solid grey" mr={2}>
                <Typography variant="h1" textAlign="center" fontSize="32px" fontWeight="bold" >
                  {team["Total (excl. Assoc & OSP)"]}
                </Typography>
                <Typography variant="h6" textAlign="center" fontWeight="bold">
            Total Workers
                </Typography>
              </Box>
              <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="space-between" my={-1}>
                <Box border="1px solid grey" my={1} p={2} textAlign="center">
                  <Typography variant="body1" fontSize="24px" fontWeight="bold">
                    {team.Employees}
                  </Typography>
                  <Typography variant="body1" fontSize="16px">
              Total Employees
                  </Typography>
                </Box>
                <Box border="1px solid grey" my={1} p={2} textAlign="center">
                  <Typography variant="body1" fontSize="24px" fontWeight="bold">
                    {team["Contingents (excl. Assoc & OSP)"]}
                  </Typography>
                  <Typography variant="body1" fontSize="16px">
              Total Contingents
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        ):(
          <Typography variant="body1">No data available</Typography>
        )}
        
      </Grid>
    </Grid>
  );
};

export default TeamLevelHeadcount;
