import React, { useState } from "react";

import { Download } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useLazyGetInsightsGenieDataQuery } from "store/slices/insightsGenieSlice";
import { downloadFile } from "utils/downloadFile";

const DownloadExcel = ({ setDialog }) => {
  const [currentParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [getDownloadUrlOrJobId] = useLazyGetInsightsGenieDataQuery();
  const domain = currentParams.get("domain");
  const division = currentParams.get("division");
  const dates = currentParams.get("dates")?.split(",") || [];
  const l2_org = currentParams.get("l2_org");
  const l3_org = currentParams.get("l3_org");
  const l4_org = currentParams.get("l4_org");
  const geo = currentParams.get("geo");
  const country = currentParams.get("country");
  const startdate = currentParams.get("startdate");
  const enddate = currentParams.get("enddate");
  const annualized = currentParams.get("annualized");

  const handleDownload = async () => {
    try {
      setIsLoading(true);
      if (!domain || !division) {
        setIsLoading(false);
        return;
      }
      const response = await getDownloadUrlOrJobId({
        division,
        domain,
        dates,
        l2_org,
        l3_org,
        l4_org,
        geo, 
        country,
        startdate,
        enddate,
        annualized,
      }).unwrap();

      if (response?.startsWith("http")) {
        const url = response;
        downloadFile(url);
      } else {
        // save job_id to localstorage
        // show a popup and setup polling
        const jobId = response;
        setDialog({ show: true, jobId: jobId });
      }

      setIsLoading(false);
    } catch (error) {
      console.error({ error });
      setIsLoading(false);
    }
  };

  return (
    <Box mt={2}>
      <LoadingButton
        disabled={!domain || !division || !dates.length || domain.toLowerCase().includes("coming soon")}
        variant="contained"
        onClick={handleDownload}
        loading={isLoading}
        startIcon={<Download />}
        color="primary"
      >
          Download Presentation
      </LoadingButton>
    </Box>
  );
};

export default DownloadExcel;
