import React, { useMemo, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import { MaterialReactTable, MRT_ToggleFullScreenButton, useMaterialReactTable } from "material-react-table";
import { useGetAllUsersQuery, usePostUserMutation, useUpdateUserMutation } from "store/slices/userSlice";

import ExpandableList from "./ExpandableList/ExpandableList";
import UserEditor from "./UserEditor";


const UserManagement = () => {
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const { data: users, isLoading } = useGetAllUsersQuery();
  const [createUser] = usePostUserMutation();
  const [updateUser] = useUpdateUserMutation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (row) => {
    setSelectedUser(row);
    setOpen(true);
  };

  const handleCreateNew = () => {
    setSelectedUser(null);
    setOpen(true);
  };

  const handleCreate = async (values) => {
    return await createUser(values).unwrap();
  };

  const handleUpdate = async (values) => {
    return await updateUser(values).unwrap();
  };

  const columns = useMemo(() => [
    {
      accessorKey: "actions",
      header: "Actions",
      type: "actions",
      size: 80,
      enableSorting: false,
      enableGlobalFilter: false, 
      Cell: ({ row }) => (
        <>
          <IconButton onClick={() => handleEdit(row.original)}>
            <EditOutlinedIcon />
          </IconButton>
        </>
      ),
    },
    { 
      accessorKey: "name", 
      header: "User", 
      size: 200,
      enableGlobalFilter: true, 
      accessorFn: (dataRow) => dataRow.name,
      Cell: ({ row, cell }) => (
        <Box display="flex" alignItems="center">
          {cell.getValue()}
          {!row.original.is_active && (
            <Tooltip arrow title="User is inactive">
              <PersonOffIcon color="error" sx={{ ml: 1, width: "16px" }} />
            </Tooltip>
          )}
        </Box>
      )
    },
    { 
      accessorKey: "is_admin", 
      header: "User Type", 
      size: 120,
      enableGlobalFilter: false, 
      accessorFn: (dataRow) => (dataRow.is_admin ? "Admin" : "Regular User")
    },
    { 
      accessorKey: "personas", 
      header: "Persona", 
      size: 160,
    },
    {
      accessorKey: "last_modified_on",
      header: "Last Updated",
      size: 120,
      accessorFn: (dataRow) => dayjs(dataRow.last_modified_on).format("MM/DD/YYYY")
    },
    {
      accessorKey: "dashboards",
      header: "Dashboards",
      size: 300,
      enableSorting: false,
      enableGlobalFilter: false, 
      Cell:({ row }) => (
        <ExpandableList personaPermissions={row.original.persona_permissions?.dashboards || []} overridePermissions={row.original.override_permissions?.dashboards || []} />
      )
    },
    {
      accessorKey: "research",
      header: "Researches",
      size: 300,
      enableSorting: false,
      enableGlobalFilter: false, 
      Cell:({ row }) => (
        <ExpandableList personaPermissions={row.original.persona_permissions?.research || []} overridePermissions={row.original.override_permissions?.research || []} />
      )
    },
    {
      accessorKey: "blog",
      header: "Blogs",
      size: 300,
      enableSorting: false,
      enableGlobalFilter: false, 
      Cell:({ row }) => (
        <ExpandableList personaPermissions={row.original.persona_permissions?.blogs || []} overridePermissions={row.original.override_permissions?.blogs || []} />
      )
    }, 
    {
      accessorKey: "employee_listening",
      header: "Employee Listening",
      enableSorting: false,
      enableGlobalFilter: false, 
      size: 300,
      Cell:({ row }) => <ExpandableList personaPermissions={row.original.persona_permissions?.["employee listening"] || []} overridePermissions={row.original.override_permissions?.["employee listening"] || []} />
    },
  ], []);

  const table = useMaterialReactTable({
    columns,
    data: users?.users || [],
    state: {
      isLoading: isLoading,
      showProgressBars: isLoading,
    },
    muiSkeletonProps: {
      animation: "wave",
    },
    initialState: { 
      pagination: { pageSize: 5, pageIndex: 0 },
      showGlobalFilter: true, //show the global filter by default
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10]
    },
    enableColumnActions: false,
    enableGlobalFilterModes: true,
    positionGlobalFilter: "left",
    muiSearchTextFieldProps: {
      placeholder: "Search all users",
      sx: { minWidth: "300px" },
      variant: "outlined",
    },
    enableColumnFilters: false,
    renderToolbarInternalActions: ({ table }) => (
      <Box display="flex" alignItems="center">
        <Button
          onClick={handleCreateNew}
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
        >
        Create New
        </Button>
        <MRT_ToggleFullScreenButton table={table} />
      </Box>
    ),
  });


  return (
    <>
      <Box mt={2}>
        <MaterialReactTable
          table={table}
        />
      </Box>
      <UserEditor 
        open={open} 
        onClose={handleClose} 
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        user={selectedUser}
        availableTreeValues={users?.portal_items || []}
      />
    </>
  );
};

export default UserManagement;
